import React from 'react';
import { connect } from 'react-redux';
import Callout from './Callout';
import { PickItemPanel, PickElement } from './Components';
import * as itemAction from '../../redux/items';
import * as editItemAction from '../../redux/edit-item';
import * as inputAction from '../../redux/input';
import * as typeAction from '../../redux/type';
import * as modalAction from '../../redux/modal';
import * as styles from '../../styles';

class TodoGroup extends React.Component {
  edit(item) {
    const { setEditItem, setType, setInput, openModal } = this.props;
    setEditItem(item);
    setType(item.type);
    setInput({ ...item.data });
    openModal('pick');
  }
  render() {
    const { auth, items, editItem, hideCallout } = this.props;
    const editId = editItem && editItem.id;
    const list = items.map(item => (
      <PickElement
        key={item.id}
        style={{
          paddingTop: editId === item.id ? 5 : undefined,
          marginBottom: editId === item.id ? 5 : undefined,
          border: editId === item.id ? '1px solid blue' : '',
          display: 'flex',
        }}
      >
        <a
          target="_blank"
          href={`https://google.com/search?q=${item.data.text.replace(
            ' ',
            '+',
          )}`}
          rel="noopener noreferrer"
        >
          <img
            src={item.data.image_url}
            alt={item.data.image_url}
            width="100px"
            height="100px"
          />
        </a>
        <div style={{ display: 'block', width: '70%', paddingLeft: 20 }}>
          <div
            style={{
              color: styles.Gray,
              fontSize: styles.FontSizeSmall,
              textTransform: 'uppercase',
              fontFamily: styles.FontFamilyCereal,
            }}
          >
            {item.data.type}
          </div>
          <div
            style={{
              textTransform: 'capitalize',
              fontFamily: styles.FontFamilyCooper,
              fontSize: styles.FontSizeLarge,
            }}
          >
            {item.data.text}{' '}
          </div>
        </div>

        {auth.loggedIn && auth.id === item.created_by_id && (
          <div
            to="#"
            onClick={() => this.edit(item)}
            style={{
              textDecoration: 'none',
              width: '10%',
              textAlign: 'right',
              backgroundColor: '#F1F8FB',
            }}
          >
            ...
          </div>
        )}
      </PickElement>
    ));
    return (
      <div>
        {!hideCallout && <Callout item={this.props.items[0]} />}
        <PickItemPanel {...this.props}>{list}</PickItemPanel>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    editItem: state.editItem,
  }),
  {
    updateItem: itemAction.update,
    setEditItem: editItemAction.set,
    setInput: inputAction.set,
    setType: typeAction.set,
    openModal: modalAction.open,
  },
)(TodoGroup);
