import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import 'boxicons';
import './Card.scss';

export const Card = forwardRef(function Card(props, ref) {
  return (
    <div ref={ref} className="Card" {...props.droppableProps}>
      {props.children}
    </div>
  );
});

export const CardBody = props => (
  <div
    className={classNames('CardBody', {
      'border-top': props.border === 'both' || props.border === 'top',
      'border-bottom': props.border === 'both' || props.border === 'bottom',
    })}
  >
    {props.children}
  </div>
);

export const CardFooterLink = props => {
  const { to, onClick, children } = props;

  if (to) {
    return (
      <Link className="CardFooterLink" to={to}>
        {children}
      </Link>
    );
  } else {
    return (
      <div className="CardFooterLink" onClick={onClick}>
        {children}
      </div>
    );
  }
};
