import React from 'react';
import { withRouter } from 'react-router-dom';
import idx from 'idx';
import 'boxicons';
import Account from '../Account/Account';
import NavLink from './NavLink';

import './Nav.scss';

const Nav = props => {
  const { location } = props;
  const pathParts = idx(location, _ => _.pathname.split('/'));
  const active = pathParts && pathParts[1];

  return (
    <div className="Nav">
      <Account product={active} />
      <div className="products">
        {navHelper('home', 'Home', 'home', active, true)}
        {navHelper('circles', 'Circles', 'building-house', active)}
        <div className="mobile-spacer" />
        {navHelper('journal', 'Journal', 'pencil', active)}
        {navHelper('library', 'Library', 'book-open', active)}
      </div>
    </div>
  );
};

function navHelper(product, name, icon, activeProduct, defaultProduct = false) {
  const active =
    (defaultProduct && !activeProduct) || activeProduct === product;
  return (
    <NavLink product={product} active={active}>
      {active ? (
        <box-icon name={icon} type="solid" />
      ) : (
        <box-icon name={icon} type="regular" />
      )}
      <span>{name}</span>
    </NavLink>
  );
}

export default withRouter(Nav);
