import React, { forwardRef } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { H3 } from './Type';
import { Checkbox } from './Checkbox';
import { Avatar } from './Avatar';
import { formatTime } from '../../../utils/date-format';
import { COMPLETE, COMPLETABLE, TYPE_FILE } from '../constants';
import UserColors from '../UserColors';
import './Item.scss';

export const Item = forwardRef(function Item(props, ref) {
  const {
    item,
    auth,
    hideUnreadBadge,
    openForm,
    cardMarkComplete,
    userName,
    variant,
  } = props;

  const {
    id,
    title,
    day,
    date,
    time,
    end_time,
    status,
    assigned_to_id,
    read_at,
    type,
    description,
  } = item;

  const completable = [COMPLETABLE, COMPLETE].includes(status);
  const complete = completable && status === COMPLETE;

  const startTime = formatTime(date, time, end_time);
  const endTime = formatTime(date, end_time);
  const formattedTime =
    end_time && time === end_time
      ? endTime
      : `${startTime}${endTime ? `-${endTime}` : ''}`;

  const past = date && moment().startOf('day').isAfter(moment(date));

  const assignedToName = assigned_to_id && userName(assigned_to_id);
  const assignedToNamePrefix = assignedToName && assignedToName.substring(0, 2);

  return (
    <div
      ref={ref}
      className={classNames('Item', {
        unread: !hideUnreadBadge && !read_at,
        inactive: (completable && complete) || past,
        dragging: variant === 'dragging',
      })}
      onClick={() => openForm(day, id)}
      {...props.draggableProps}
      {...props.dragHandleProps}
    >
      <div className="info">
        <H3>{title}</H3>
        {time && <div className="time">{formattedTime}</div>}
        {type === TYPE_FILE && description && (
          <div className="time">{description}</div>
        )}
      </div>
      <div className="widgets">
        {completable && (
          <Checkbox
            id={id}
            checked={complete}
            onChange={cardMarkComplete.bind(this, item)}
          />
        )}
        {assigned_to_id && (
          <Avatar
            bgClass={UserColors.getUserBgClass(auth.users, assigned_to_id)}
          >
            {assignedToNamePrefix}
          </Avatar>
        )}
      </div>
    </div>
  );
});

export const BlankItem = () => <div className="Item blank"></div>;
