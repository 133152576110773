const EDITITEM_SET = 'EDITITEM_SET';
const initialState = null;

export function set(value) {
  return { type: EDITITEM_SET, value };
}

export function clear() {
  return { type: EDITITEM_SET, value: initialState };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === EDITITEM_SET) {
    result = action.value;
  }
  return result;
}
