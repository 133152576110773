const userColors = [
  '#E85A44',
  '#3D58A2',
  '#3D835E',
  '#F09845',
  '#8D67AB',
  '#956437',
  '#71AAB7',
  '#D491A1',
];

export function getUserColor(users, userId) {
  for (var i = 0; i < users.length && i < userColors.length; i++) {
    if (users[i].id === userId) {
      return userColors[i];
    }
  }
  return 'grey';
}

const familyColors = [
  '#D491A1',
  '#71AAB7',
  '#956437',
  '#8D67AB',
  '#F09845',
  '#3D835E',
  '#3D58A2',
  '#E85A44',
];

export function getFamilyColor(families, familyId) {
  const sorted = [...families].sort((a, b) => a.id - b.id);
  for (var i = 0; i < sorted.length && i < familyColors.length; i++) {
    if (sorted[i].id === familyId) {
      return familyColors[i];
    }
  }
  return 'grey';
}
