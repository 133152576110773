import React from 'react';
import Modal from 'react-modal';
import XIcon from '../Icons/BigX.png';
import './JournalModal.scss';

Modal.setAppElement('#root');

const JournalModal = props => {
  const { closeForm, children, title, open } = props;
  return (
    <Modal
      className="JournalModal"
      overlayClassName="JournalModal__overlay"
      isOpen={open}
      onRequestClose={closeForm}
      closeTimeoutMS={100}
    >
      <div className="title">
        <div>{title}</div>
        {closeForm && (
          <div>
            <img className="x" src={XIcon} alt="X" onClick={closeForm} />
          </div>
        )}
      </div>
      {children}
    </Modal>
  );
};

export default JournalModal;
