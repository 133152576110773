import moment from 'moment';
import { DATE_FORMAT } from './constants';

export function getLinks(text) {
  const regex = /((http|ftp|https):\/\/)?([\w_+-]+(?:(?:[.@][\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
  const matches = (text && text.match(regex)) || [];
  return [...new Set(matches)];
}

// Sort by date and time and then id
export function sortItems(items) {
  return [...items].sort((a, b) => {
    if (a.time && !b.time) {
      return 1;
    }
    if (!a.time && b.time) {
      return -1;
    }
    if (!a.time && !b.time) {
      return b.id - a.id;
    }
    if (a.time && b.time) {
      return moment(a.date + ' ' + a.time) - moment(b.date + ' ' + b.time);
    } else {
      return a.id - b.id;
    }
  });
}

/** Sort by time only - ignore the date */
export function sortItemsByTime(items) {
  const date = moment().format(DATE_FORMAT);
  return [...items].sort((a, b) => {
    if (a.time && !b.time) {
      return 1;
    }
    if (!a.time && b.time) {
      return -1;
    }
    if (!a.time && !b.time) {
      return b.id - a.id;
    }
    if (a.time && b.time) {
      return (
        moment((date || a.date) + ' ' + a.time) -
        moment((date || b.date) + ' ' + b.time)
      );
    } else {
      return a.id - b.id;
    }
  });
}

/** If time is changed, get the end time adjusted by the same amount
 */
export function followTime(endTime, originalStart, newStart) {
  const date = moment().format('YYYY-MM-DD');
  const timeBefore = moment(date + ' ' + originalStart);
  const timeNow = moment(date + ' ' + newStart);
  const diff = timeNow.diff(timeBefore, 'minutes');
  return moment(date + ' ' + endTime)
    .add(diff, 'minutes')
    .format('HH:mm');
}

/**
 * Replace special characters including slash with ascii code.
 * Remore emojis completely
 */
export function escapeURI(text) {
  // Option to remove emojis
  // text.replace(
  //   /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
  //   '',
  // ),
  return encodeURI(text).replace(/\//g, '%2F').replace(/'/g, '%20');
}

export function getBotUser(auth) {
  const botUser = auth.users.find(user => user.name === 'bot');
  return botUser && botUser.id;
}
