import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import * as styles from '../../styles';

export const Container = styled.div`
  flex: 1;
  display: flex;
  color: black;
  font-family: ${styles.FontFamilyCereal};
  overflow-x: hidden;
`;

export const CalendarPanel = styled.div`
  background-color: ${styles.BackgroundColor};
  flex: 1;
  display: flex;
  overflow: scroll;
  height: calc(100vh - 100px);
  scroll-snap-type: x proximity;
  @media (max-width: 768px) {
    height: calc(100vh - 220px);
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex: 1;
  background: url('/dashboard-illustration.png') repeat-x;
  background-size: auto 80px;
  background-attachment: local;
  align-items: flex-start;
`;

export const DayTitle = styled.div`
  border-bottom: 1px solid ${styles.BorderColor};
  color: #333;
  font-family: ${styles.FontFamilyCooper};
  font-weight: bold;
  font-size: 23px;
  padding-bottom: 10px;
  margin-top: 24px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

export const Checkbox = styled.div`
  position: relative;
  height: 20px;
  width: 20px;

  @media (max-width: 768px) {
    height: 28px;
    width: 28px;
  }

  input[type='checkbox'] {
    opacity: 0;
    height: 20px;
    width: 20px;
    margin: 0;
    @media (max-width: 768px) {
      height: 28px;
      width: 28px;
    }
  }

  label {
    position: absolute;
    right: -5px;
    padding-left: 5px;
    cursor: pointer;

    @media (max-width: 768px) {
      padding-top: 3px;
    }

    @media (hover: hover) {
      :hover::after {
        box-shadow: inset 0 0 6px rgb(145, 182, 165);
      }
    }

    ::after {
      content: '';
      cursor: pointer;
      position: absolute;
      top: 0;
      left: -20px;
      height: 20px;
      width: 20px;
      border: 1px solid rgb(191, 191, 191);
      box-sizing: border-box;
      border-radius: 4px;
      transition: box-shadow 0.1s ease;

      @media (max-width: 768px) {
        height: 28px;
        width: 28px;
        left: -28px;
      }
    }
  }

  input[type='checkbox']:checked + label::after {
    border: 0;
    background-color: rgb(145, 182, 165);
    background-image: url(${props => props.icon});
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
  }
`;

export const ToolsPanel = styled.div`
  height: 100px;
  border-top: 1px solid black;
`;

export const Button = styled.a`
  cursor: pointer;
`;

export const DateButton = styled.button`
  cursor: pointer;
  border: none;
`;

export const Select = styled.select`
  appearance: none;
  width: 100%;
  height: 42px;
  background: url(${props => props.icon}) no-repeat scroll 8px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  font-size: 16px;
  font-family: ${styles.FontFamilyCereal}
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }

  option {
    color: black;
    background-color: white;
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const FormField = styled.div`
  display: flex;
  margin-bottom: 2px;
`;

export const MobileFormField = styled.div`
  display: flex;
  width: calc(100vw - 20px);
  margin-left: 10px;
`;

export const TextInput = styled.input`
  width: 342px;
  background: url(${props => props.icon}) no-repeat scroll 7px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }
`;

export const TextAreaInput = styled(TextareaAutosize)`
  width: 100%;
  background: url(${props => props.icon}) no-repeat scroll 7px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }
  resize: none;
`;

export const FormIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-left: 7px;
`;

export const HistoricalSuggestions = styled.div`
  position: absolute;
  width: 380px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
`;

export const HistoricalItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
  padding-left: 20px;
  :hover {
    background-color: ${styles.BackgroundColor};
  }
`;

export const SuggestionsPanel = styled.div`
  background-color: ${styles.BackgroundColor};
  padding: 24px;
  padding-left: 32px;
  padding-bottom: 0px;
  border-radius: 8px;
  width: 340px;
  z-index: 0;
  transition: margin-left 0.2s;
`;

export const SuggestionItem = styled.div`
  display: flex;
  padding: 6px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  :hover {
    background-color: rgb(0, 0, 0, 0.05);
  }
`;

export const FormButton = styled.button`
  padding: 10px 15px;
  background-color: ${({ variant }) =>
    variant === 'blue' ? '#3d58a2' : '#f2f6fb'};
  border-radius: 8px;
  font-weight: ${({ variant }) => (variant === 'blue' ? 'bold' : 'normal')};
  font-size: 15px;
  line-height: 22px;
  color: ${({ variant }) => (variant === 'blue' ? '#fff' : '#333')};
  border: none;
  cursor: pointer;
  transition: background-color 0.1s ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ variant }) =>
        variant === 'blue' ? '#364c88' : '#e8edf3'};
    }
  }
`;

export const AddNewButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;

  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: white;
  font-size: 70px;
  line-height: 54px;
  text-align: center;

  background-color: ${styles.Blue};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;

  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    background-color: #364c88;
    background-size: 60%;
  }
`;
