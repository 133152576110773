import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { callApi } from '../../utils/callApi';
import {
  Container,
  SidePanel,
  DatesContainer,
  CenterPanel,
  CalendarPanel,
  ItemBlock,
  GrayBlock,
} from './Components';

import { Button as NewButton } from '../Home/components/Button';
import EditJournal from './EditJournal';
import * as styles from '../../styles';

class Journal extends React.Component {
  state = {
    items: [],
    selected: undefined,
    mobileShowSidePanel: true,
  };

  componentDidMount() {
    this.getItems();
  }

  getItems() {
    callApi('GET', 'api/journal').then(items => {
      this.setState({ items });
      const { selected } = this.state;
      if (!selected && window.innerWidth > 768 && items && items[0]) {
        this.showItem(items[0].id);
      }
    });
  }

  async getItem(id) {
    return callApi('GET', `api/journal/${id}`);
  }

  async showItem(id) {
    const selected = await this.getItem(id);
    if (selected && selected.id) {
      this.setState({ selected, mobileShowSidePanel: false });
    }
  }

  async openForm(id) {
    const item = id && (await this.getItem(id));
    this.setState({
      form: item ? item : { id: -1, type: 'journal', title: '' },
    });
  }

  closeForm() {
    this.setState({ form: undefined });
  }

  async saveAndCloseForm(e) {
    e.preventDefault();
    const { form } = this.state;
    let id = form.id;
    if (form.id > 0) {
      await callApi('PUT', `api/items/${form.id}`, undefined, {
        ...form,
        type: 'journal',
      });
    } else {
      id = await callApi('POST', 'api/items', undefined, form);
    }
    this.getItems();
    this.setState({ selected: { ...form, id }, form: undefined });
  }

  inputChange(e) {
    e.preventDefault();
    const { form } = this.state;
    this.setState({
      form: { ...form, [e.target.name]: e.target.value },
    });
  }

  editForm() {
    const { form } = this.state;
    return (
      <EditJournal
        form={form}
        inputChange={e => this.inputChange(e)}
        closeForm={() => this.closeForm()}
        saveAndCloseForm={e => this.saveAndCloseForm(e)}
      />
    );
  }

  card(item) {
    const { description } = item;
    const subbed =
      description &&
      description
        .replace(/\r\n\r\n/g, '##NEWLINE##')
        .replace(/\r\n/g, ' ')
        .replace(/##NEWLINE##/g, '\r\n\r\n');

    const regex = /(.*[\n\r])*(?=[> ]*?on(.*journal@modernvillage.com.*wrote))/gi;
    const matches = (subbed && subbed.match(regex)) || [];
    const parsed = matches[0] || subbed;

    return (
      <ItemBlock
        key={item.id}
        onClick={() => this.openForm(item.id)}
        style={{ cursor: 'pointer' }}
      >
        <div
          style={{
            fontSize: styles.FontSizeMedium,
            fontWeight: 'bold',
            marginBottom: 10,
          }}
        >
          {moment(item.created_at).format('dddd, MMMM D')}
        </div>
        {item.title && (
          <div
            style={{ color: 'grey', whiteSpace: 'pre-line', lineHeight: 1.5 }}
          >
            {item.title}
          </div>
        )}
        <div
          style={{ color: 'black', whiteSpace: 'pre-line', lineHeight: 1.5 }}
        >
          {parsed}
        </div>
      </ItemBlock>
    );
  }

  render() {
    const { mobileShowSidePanel, items, selected, form } = this.state;
    const isMobile = window.innerWidth <= 768;
    return (
      <Container className="Journal">
        {form && this.editForm()}
        {(mobileShowSidePanel || !isMobile) && (
          <SidePanel isMobile={isMobile}>
            <DatesContainer>
              {items.length === 0 && <GrayBlock>None</GrayBlock>}
              <div style={{ height: 'calc(100vh - 170px)' }}>
                {items.map(item => (
                  <ItemBlock
                    key={item.id}
                    style={{ cursor: 'pointer', padding: 16 }}
                    onClick={() => this.showItem(item.id)}
                  >
                    <div
                      style={{
                        fontSize: styles.FontSizeMedium,
                        fontWeight: 'bold',
                      }}
                    >
                      {moment(item.created_at).format('dddd, MMMM D')}
                    </div>
                    {item.title && (
                      <div style={{ color: styles.Gray }}>{item.title}</div>
                    )}
                    <div
                      style={{
                        color: styles.Gray,
                      }}
                    >
                      {item.description && item.description.substring(0, 50)}
                    </div>
                  </ItemBlock>
                ))}
              </div>
            </DatesContainer>
            <div style={{ padding: 20 }}>
              <button
                style={{ fontSize: styles.FontSizeSmall }}
                onClick={() => this.getItems()}
              >
                Refresh
              </button>
              {isMobile && (
                <button
                  style={{ fontSize: styles.FontSizeSmall, marginLeft: 10 }}
                  onClick={() => this.openForm()}
                >
                  New
                </button>
              )}
            </div>
          </SidePanel>
        )}
        {(!mobileShowSidePanel || !isMobile) && (
          <CenterPanel>
            {isMobile && !form && (
              <div
                style={{
                  padding: '20px',
                  paddingBottom: 0,
                  position: 'absolute',
                  top: 0,
                  right: '10px',
                  zIndex: 10,
                }}
              >
                <button
                  onClick={() =>
                    this.setState({
                      mobileShowSidePanel: !mobileShowSidePanel,
                    })
                  }
                >
                  List
                </button>
              </div>
            )}
            <CalendarPanel>
              <div
                style={{
                  height: 'calc(100vh - 200px)',
                  marginTop: isMobile ? 60 : 65,
                  overflow: 'auto',
                }}
              >
                {!selected && <GrayBlock>None</GrayBlock>}
                {selected && this.card(selected)}
              </div>
            </CalendarPanel>
          </CenterPanel>
        )}
        {!isMobile && (
          <NewButton
            variant="small"
            style={{ position: 'fixed', top: 16, right: 16, zIndex: 3 }}
            onClick={() => this.openForm()}
          >
            <box-icon name="plus" />
            New
          </NewButton>
        )}
      </Container>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  {},
)(Journal);
