import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import idx from 'idx';
import { callApi } from '../../utils/callApi';
import * as analytics from '../../utils/analytics';
import { getAccessToken } from '../../redux/auth';
import Login from '../Login/Login';
import Join from '../Login/Join';
import Signup from '../Login/Signup';
import Picks from '../Picks/Picks';
import Nav from '../Nav/Nav';
import Home from '../Home/Home';
import Circles from '../Circles/Circles';
import Library from '../Library/Library';
import Journal from '../Journal/Journal';
import JournalStub from '../JournalStub/JournalStub';
import ConnectCalendars from '../Account/ConnectCalendars';
import { storeLogin, reset } from '../../redux/auth';
import { version } from '../../../package.json';

class App extends React.Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    console.log(version);
    this.login();
  }

  async login() {
    // check auth - with access token only
    if (getAccessToken()) {
      const auth = await callApi('POST', 'login');
      if (auth && auth.name) {
        this.props.handleStoreLogin(auth);
        analytics.track('Login success', { userId: auth.id, method: 'token' });
        analytics.identify(auth.id, {
          method: 'token',
          familyId: auth.family_id,
          familyName: auth.family,
          name: auth.name,
        });
      }
    }
    this.setState({ loading: false });
  }

  render() {
    const { loggedIn, auth } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }
    const hasJournal = auth.preferences && auth.preferences.show_journal;

    return (
      <Router>
        <Switch>
          <Route path="/picks/:hash" component={Picks} />
          <Route path="/joinXX/a/:groupHash" component={Join} />
          {!loggedIn && (
            <Switch>
              <Route path="/signup/:magicToken" component={Signup} />
              <Route path="/password/:magicToken" component={Signup} />
              <Route path="/joinXX/:familyHash" component={Join} />
              <Route path="/joinXX" component={Join} />
              <Route component={Login} />
            </Switch>
          )}
          {loggedIn && (
            <>
              <Nav />
              <Switch>
                <Route path="/calendar" component={ConnectCalendars} />
                <Route path="/calendar/:code" component={ConnectCalendars} />
                <Route path="/circles" component={Circles} />
                <Route
                  path="/journal"
                  component={hasJournal ? Journal : JournalStub}
                />
                <Route path="/library" component={Library} />
                <Route path="/home" component={Home} />
                <Route component={Home} />
              </Switch>
            </>
          )}
        </Switch>
      </Router>
    );
  }
}

export default connect(
  state => ({
    loggedIn: idx(state, _ => _.auth.loggedIn),
    auth: state.auth,
  }),
  {
    handleStoreLogin: storeLogin,
    handleLogout: reset,
  },
)(App);
