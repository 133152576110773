import React from 'react';
import * as styles from '../../../styles';
import { SuggestionItem } from '../Components';

export default class SearchResultsMobile extends React.Component {
  state = {
    showSuggestions: true,
    showPages: 1,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick(e) {
    const { setShowMobileSearchResults } = this.props;
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    setShowMobileSearchResults(false);
  }

  renderItem(index, item) {
    const { useSuggestion } = this.props;
    return (
      <div
        style={{
          fontSize: 14,
          cursor: 'pointer',
          padding: 6,
          paddingLeft: 20,
        }}
        key={index}
        onClick={() => useSuggestion(item)}
      >
        <div style={{ fontWeight: 'bold' }}>{item.title}</div>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: styles.Gray,
          }}
        >
          {item.description}
        </div>
      </div>
    );
  }

  render() {
    const {
      form,
      historicalSuggestions,
      useSuggestion,
      loadingSuggestions,
      suggestions,
    } = this.props;
    const { showSuggestions, showPages } = this.state;
    const itemsPerPage = 2;
    const numItems =
      (historicalSuggestions && historicalSuggestions.length) || 0;
    const history = [];
    for (
      let index = 0;
      index < itemsPerPage * showPages && index < numItems;
      index++
    ) {
      history.push(this.renderItem(index, historicalSuggestions[index]));
    }

    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 100,
          borderRadius: '8px',
          backgroundColor: styles.White,
          boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25)',
          maxHeight: 360,
          overflowY: 'auto',
          margin: 10,
          marginTop: -2,
          width: 'calc(100vw - 20px)',
        }}
        ref={node => (this.node = node)}
      >
        {history}
        {numItems > itemsPerPage * showPages && (
          <div
            style={{
              color: styles.Blue,
              padding: 10,
              paddingLeft: 20,
              cursor: 'pointer',
            }}
            onClick={() => this.setState({ showPages: showPages + 1 })}
          >
            Show more...
          </div>
        )}
        <hr style={{ color: styles.Gray, opacity: 0.5 }} />
        <div>
          <div
            style={{
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: showSuggestions ? 5 : 20,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                fontFamily: styles.FontFamilyCooper,
                fontSize: 18,
                display: 'flex',
              }}
            >
              <div>
                <img
                  alt="Lightbulb"
                  src="/icons/Lightbulb.png"
                  style={{ width: 24, height: 24 }}
                />
              </div>
              <div
                style={{
                  paddingTop: 4,
                  marginLeft: 5,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                Related Suggestions
              </div>
            </div>

            <div
              style={{
                color: styles.Blue,
                paddingTop: 5,
                cursor: 'pointer',
              }}
              onClick={() =>
                this.setState({ showSuggestions: !showSuggestions })
              }
            >
              {showSuggestions ? 'Hide' : 'Show'}
            </div>
          </div>
          {showSuggestions &&
            form.title &&
            form.title.length > 1 &&
            suggestions.length > 0 && (
              <React.Fragment>
                <div>
                  {suggestions.map((suggestion, index) => (
                    <SuggestionItem
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                      }}
                      key={index}
                      onClick={() => useSuggestion(suggestion)}
                    >
                      <div
                        style={{
                          backgroundColor: 'rgb(240,207,184)',
                          borderRadius: 8,
                          padding: 6,
                          height: 32,
                          width: 32,
                          minWidth: 32,
                          minHeight: 32,
                          marginRight: 10,
                        }}
                      >
                        <img
                          alt="Suggestion"
                          src={suggestion.image_url || '/logo192.png'}
                          style={{
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            minHeight: 32,
                          }}
                        />
                      </div>
                      <div>
                        <div
                          style={{
                            width: 'calc(100vw - 120px)',
                            fontWeight: 'bold',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginTop: !suggestion.description && 10,
                          }}
                        >
                          {suggestion.title}
                        </div>
                        {suggestion.description && (
                          <div
                            style={{
                              color: styles.Gray,
                              width: 'calc(100vw - 120px)',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {suggestion.description}
                          </div>
                        )}
                      </div>
                    </SuggestionItem>
                  ))}
                </div>
              </React.Fragment>
            )}
          {showSuggestions &&
            form.title &&
            form.title.length > 1 &&
            suggestions.length === 0 &&
            !loadingSuggestions && (
              <React.Fragment>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: -10,
                    paddingBottom: 15,
                  }}
                >
                  <img
                    style={{ height: 126 }}
                    src="/Suggestions-No-Results-Illustration.png"
                    alt="No results"
                  />
                  <div>No results for {form.title.substring(0, 30)}</div>
                </div>
              </React.Fragment>
            )}
        </div>
      </div>
    );
  }
}
