const CHANNELS_SET = 'CHANNELS_SET';
const initialState = [];

export function set(channels) {
  const general = { id: 0, name: 'general' };
  return { type: CHANNELS_SET, channels: [general, ...channels] };
}
export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === CHANNELS_SET) {
    result = action.channels;
  }
  return result;
}
