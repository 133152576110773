import React from 'react';
import * as styles from '../../../styles';
import { SuggestionItem } from '../Components';

// Turn https://www.youtube.com/watch?v=dQw4w9WgXcQ -> youtube.com if the string is only the URL
// Otherwise just return the original string
const truncateURL = text => {
  try {
    const url = new URL(text);
    return url.hostname.replace(/^www\./, '');
  } catch {
    return text;
  }
};

export default class Suggestions extends React.Component {
  render() {
    const {
      suggestions,
      loadingSuggestions,
      useSuggestion,
      hide,
      show,
      showSuggestions,
      query,
      dirty,
      maxHeight,
    } = this.props;
    const showResults =
      showSuggestions && query && query.length > 1 && suggestions.length > 0;
    const showNoResults =
      !showResults &&
      showSuggestions &&
      query &&
      query.length > 1 &&
      dirty &&
      suggestions.length === 0 &&
      !loadingSuggestions;

    const showDefault =
      !showNoResults &&
      !showResults &&
      showSuggestions &&
      (!query ||
        query.length < 2 ||
        suggestions.length === 0 ||
        loadingSuggestions);

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontFamily: styles.FontFamilyCooper,
              fontSize: 18,
              display: 'flex',
              paddingLeft: 20,
            }}
          >
            <div>
              <img
                alt="Lightbulb"
                src="/icons/Lightbulb.png"
                style={{ width: 24, height: 24 }}
              />
            </div>
            <div
              style={{
                paddingTop: 4,
                marginLeft: 5,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              Related Suggestions
            </div>
          </div>
          {showSuggestions && (
            <div
              style={{
                color: styles.Blue,
                paddingTop: 5,
                cursor: 'pointer',
              }}
              onClick={hide}
            >
              Hide
            </div>
          )}
          {!showSuggestions && (
            <div
              style={{
                color: styles.Blue,
                cursor: 'pointer',
                height: maxHeight - 40,
                marginLeft: 100,
                paddingLeft: 14,
                marginRight: -24,
                width: 72,
              }}
              onClick={() => show()}
            >
              <img
                alt="Lightbulb"
                src="/icons/Lightbulb.png"
                style={{ width: 24 }}
              />
            </div>
          )}
        </div>
        {showResults && (
          <React.Fragment>
            <hr style={{ color: styles.Gray, opacity: 0.5 }} />
            <div
              style={{
                height: maxHeight - 64,
                marginTop: -9,
                overflowY: 'auto',
              }}
            >
              {suggestions.map((suggestion, index) => (
                <SuggestionItem
                  key={index}
                  onClick={() => useSuggestion(suggestion)}
                >
                  <img
                    alt="Suggestion"
                    src={suggestion.image_url || '/logo192.png'}
                    style={{
                      height: 44,
                      width: 44,
                      minWidth: 44,
                      minHeight: 44,
                      marginRight: 10,
                      borderRadius: 8,
                    }}
                  />
                  <div>
                    <div
                      style={{
                        width: 265,
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginTop: !suggestion.description && 10,
                      }}
                    >
                      {suggestion.title}
                    </div>
                    {suggestion.description && (
                      <div
                        style={{
                          color: styles.Gray,
                          width: 265,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {truncateURL(suggestion.description)}
                      </div>
                    )}
                  </div>
                </SuggestionItem>
              ))}
            </div>
          </React.Fragment>
        )}
        {showNoResults && (
          <React.Fragment>
            <hr style={{ color: styles.Gray, opacity: 0.5 }} />
            <div
              style={{
                textAlign: 'center',
                marginTop: 60,
              }}
            >
              <img
                style={{ height: 126 }}
                src="/Suggestions-No-Results-Illustration.png"
                alt="No results"
              />
              <div>No results for</div>
              <div>
                &ldquo;
                {query.length > 30 ? `${query.substring(0, 30)}\u2026` : query}
                &rdquo;
              </div>
            </div>
          </React.Fragment>
        )}
        {showDefault && (
          <div
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              color: styles.Gray,
            }}
          >
            <div
              style={{
                margin: 10,
                marginTop: 14,
                height: '100%',
                fontSize: 14,
              }}
            >
              <div>
                To see ideas sourced from the Modern Village community, just
                start typing in the ‘Title’ field:
              </div>
              <br />
              <div>
                <span role="img" aria-label="lunch">
                  🍴
                </span>{' '}
                Lunch...
              </div>
              <div>
                <span role="img" aria-label="Medal">
                  🏅
                </span>{' '}
                Get moving...{' '}
              </div>
              <div>
                <span role="img" aria-label="Books">
                  📚
                </span>{' '}
                Math...{' '}
              </div>
              <div>
                <span role="img" aria-label="Egg">
                  🍳
                </span>{' '}
                Cooking lessons...{' '}
              </div>
              <div>
                <span role="img" aria-label="Brish">
                  🖌
                </span>{' '}
                Art class...{' '}
              </div>
              <div>
                <span role="img" aria-label="Car">
                  🚗
                </span>{' '}
                Field Trip...
              </div>
              <div>
                <span role="img" aria-label="Yoga">
                  🧘‍♀️
                </span>{' '}
                Yoga...
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
