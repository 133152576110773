import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import idx from 'idx';
import socketIOClient from 'socket.io-client';
import moment from 'moment';
import * as analytics from '../../utils/analytics';
import { callApi } from '../../utils/callApi';
import { alignDays } from './Week/align-days';
import * as utils from './utils';
import { AddNewButton } from './Components';
import SubNav from './SubNav';
import Summary from './Summary/Summary';
import Dashboard from './Week/Dashboard';
import Inbox from './Inbox/Inbox';
import Files from './Files/Files';
import EditFormModal from './EditForm/EditFormModal';
import EditFormMobile from './EditForm/EditFormMobile';
import Planner2 from './Planner2/Planner2';
import BigPlusIcon from '../Icons/BigPlus.png';
import { Button } from './components/Button';
import {
  BOT,
  DATE_FORMAT,
  COMPLETE,
  COMPLETABLE,
  TYPE_FILE,
} from './constants';

import './Week/Dashboard.scss';
import './Home.scss';

export var mvSocket;

class Home extends React.Component {
  state = {
    items: [],
    loading: true,
    maxItems: 0,
    backlog: [],
    open: false,
    planner2Open: false,
    showMobileForm: false,
    form: {},
    suggestions: [],
    suggestion: undefined,
    loadingSuggestions: false,
    historicalSuggestions: [],
    showSuggestions: true,
    showCompleteInBacklog: false,
    mobileShowBacklog: true,
    weekStartDate: moment().startOf('isoWeek').format(DATE_FORMAT),
  };

  componentDidMount() {
    analytics.page('Dashboard');
    this.refreshItems();
    !mvSocket && this.connectSocket();
    this.getRandomSuggestion();
  }

  processItems(
    weekStartDate = this.state.weekStartDate,
    items = this.state.items,
  ) {
    if (!items || !Array.isArray(items)) {
      return;
    }
    const weekStart = moment(weekStartDate);
    const thisWeek =
      items &&
      items.filter(
        item =>
          item &&
          item.date &&
          moment(item.date).isSameOrAfter(weekStart) &&
          moment(item.date).isBefore(moment(weekStart).add(7, 'days')),
      );

    const backlog = items
      .filter(item => !item.date && !item.type)
      .sort((a, b) => b.id - a.id);

    let itemsByDay;
    if (!this.isMobile()) {
      itemsByDay = alignDays(thisWeek);
    } else {
      itemsByDay = [[], [], [], [], [], [], []];
      const sorted = utils.sortItems(thisWeek);
      sorted.forEach(item => {
        const day = item.date && moment(item.date);
        if (day) {
          const index = this.convertDay(day.day());
          if (!itemsByDay[index]) {
            itemsByDay[index] = [];
          }
          itemsByDay[index].push(item);
        }
      });
    }

    this.setState({
      backlog,
      itemsByDay,
      items,
      loading: false,
    });
  }

  getItems(weekStartDate, withEvents) {
    const endDate = moment(weekStartDate).add(6, 'days').format(DATE_FORMAT);
    callApi(
      'GET',
      'api/items',
      `start_date=${weekStartDate}&end_date=${endDate}${
        withEvents ? '&events=1' : ''
      }`,
    ).then(items => {
      this.processItems(weekStartDate, items);
    });
  }

  async moveTo(id, newPrev) {
    const affectedIds = await callApi('POST', 'api/items/prev', undefined, {
      id,
      order_prev: newPrev,
    });
    return this.refreshMultiple(affectedIds);
  }

  refreshItems(weekStartDate = this.state.weekStartDate) {
    this.setState({ loading: true });
    this.getItems(weekStartDate, false);
    this.setState({ loading: false });
    this.getItems(weekStartDate, true);
    callApi('GET', 'api/items/tags').then(tags => {
      this.setState({ tags });
    });
  }

  async refreshItem(id) {
    this.setState({ loading: true });
    const item = await callApi('GET', `api/items/${id}`);
    const filteredItems = this.state.items.filter(item => item.id !== id);
    const items = [...filteredItems, ...item];
    this.processItems(this.state.weekStartDate, items);
  }

  async refreshMultiple(ids) {
    if (!ids.length) {
      return;
    }
    const updated = await callApi('GET', `api/items/${ids.join(',')}`);
    const filteredItems = this.state.items.filter(
      item => !ids.includes(item.id),
    );
    const items = [...filteredItems, ...updated];
    this.processItems(this.state.weekStartDate, items);
  }

  async deleteItem(id) {
    const items = this.state.items.filter(item => item.id !== id);
    this.processItems(this.state.weekStartDate, items);
  }

  connectSocket() {
    const { auth } = this.props;
    const endpoint = `${window.location.origin}/${auth.family_id}`;
    mvSocket = socketIOClient(endpoint.replace('3000', '3001'));
    mvSocket.on(`new`, async id => {
      console.log('socket->newMVItem', id);
      this.refreshItem(+id);
    });
    mvSocket.on(`refresh`, id => {
      console.log('socket->refresh', id);
      this.refreshItem(+id);
    });
    mvSocket.on(`refresh-ids`, ids => {
      console.log('socket->refresh-ids', ids);
      this.refreshMultiple(JSON.parse(ids));
    });
    mvSocket.on(`delete`, async id => {
      console.log('socket->refresh-channels', id);
      this.deleteItem(+id);
    });
    mvSocket.on(`connect`, () => {
      console.log('connect');
    });
    mvSocket.on(`disconnect`, reason => {
      console.log('disconnected', reason);
    });
    mvSocket.on(`reconnect`, async number => {
      console.log('reconnect', number);
      await this.refreshItems();
    });
    mvSocket.on(`error`, async error => {
      console.log('error', error);
    });
  }

  openForm(dayIndex, itemId, prefillOpts = {}) {
    const date =
      dayIndex >= 0
        ? moment(this.state.weekStartDate)
            .add(dayIndex, 'd')
            .format(DATE_FORMAT)
        : undefined;
    const useModal = window.innerWidth > 768 ? true : false;
    if (!itemId) {
      const state = {
        form: { date, time: undefined, ...prefillOpts },
        historicalSuggestions: [],
        showSuggestions: true,
        loadingSuggestions: true,
      };

      this.setState(state);
    } else {
      const item = this.state.items.find(item => item.id === itemId);
      this.setState({
        suggestions: [],
      });
      if (!this.isMobile()) {
        this.getSuggestions(item.title);
      }

      this.setState({
        form: { ...item },
        historicalSuggestions: [],
        showSuggestions: true,
        showMobileSearchResults: false,
      });
      // If it's unread, mark it as read
      if (!item.read_at) {
        callApi('POST', `api/items/${itemId}/read`);
      }

      // Get the latest from the server and gcal in case it's changed
      callApi('GET', `api/items/${itemId}`).then(refreshed => {
        if (refreshed === 404) {
          // The item has been deleted from the calendar between
          // opening the dashboard and opening the item to edit
          this.setState({ open: false, showMobileForm: false });
          this.refreshItems();
          alert('Item was removed');
        } else if (
          refreshed[0].title !== item.title ||
          refreshed[0].description !== item.description ||
          refreshed[0].date !== item.date ||
          refreshed[0].time !== item.time ||
          refreshed[0].read_at !== item.read_at
        ) {
          this.setState({ form: { ...refreshed[0] } });
          this.refreshItem(item.id);
        }
      });
    }

    if (useModal) {
      this.setState({ open: true });
    } else {
      // This is only relevant for horizontally scrolling pages on mobile, so it would be good to
      // move it to just those pages when we've componentized the edit form more
      this.scrollLeft = document.getElementsByTagName('body')[0].scrollLeft;

      this.setState({ showMobileForm: true });
    }
  }

  closeForm() {
    this.setState({ open: false, showMobileForm: false }, () => {
      if (this.scrollLeft) {
        document.getElementsByTagName('body')[0].scrollTo(this.scrollLeft, 0);
        this.scrollLeft = null;
      }
    });
  }

  deleteAndCloseForm() {
    const { weekStartDate, items, form } = this.state;
    const confirm = window.confirm('Are you use you want to delete this item?');
    if (!confirm) {
      return;
    }
    callApi('DELETE', `api/items/${form.id}`);

    // Remove the item and fill the gap
    let newItems;
    if (!form.date && !form.type) {
      const below = items.find(item => item.order_prev === form.id);
      newItems = [
        ...this.state.items.filter(
          item => item.id !== form.id && (!below || item.id !== below.id),
        ),
      ];
      if (below) {
        below.order_prev = form.order_prev;
        newItems.push(below);
      }
    } else {
      newItems = [...items.filter(item => item.id !== form.id)];
    }
    this.closeForm();
    this.processItems(weekStartDate, newItems);
  }

  endOfWeek() {
    const dayOfWeek = moment().day();
    return [5, 6, 0].includes(dayOfWeek);
  }

  // If it is Friday, Saturday or Sunday then open planner for next week
  openPlanner2() {
    const thisWeek = moment().startOf('isoWeek').format(DATE_FORMAT);
    const nextWeek = moment()
      .startOf('isoWeek')
      .add(1, 'week')
      .format(DATE_FORMAT);
    this.setState({
      weekStartDate: this.endOfWeek() ? nextWeek : thisWeek,
    });
    this.setState({ planner2Open: true });
  }

  closePlanner2() {
    this.setState({ planner2Open: false });
  }

  async onNewPlannerItems(newItems) {
    const created = await callApi('POST', 'api/items', undefined, newItems);
    if (Array.isArray(created)) {
      const errors = created.filter(item => item.error).map(item => item.error);
      if (errors.length) {
        alert(`Failed to created ${errors.length} events.`);
        return;
      }
      // add the items but check they aren't there already via socket.io
      const updated = created.map(
        item =>
          !this.state.items.find(existing => existing.id === item.id) && item,
      );
      this.setState({ items: [...this.state.items, ...updated] });
      this.processItems(this.state.weekStartDate, updated);
    }
  }

  async saveAndCloseForm(e) {
    const { form, items, weekStartDate } = this.state;
    e.preventDefault();
    const errors = [];
    if (!form.title) {
      errors.push('Please enter a title');
    }
    if (form.time > form.end_time) {
      errors.push('End time must be after start time');
    }
    if (errors.length) {
      this.setState({ form: { ...form, errors } });
      return;
    }
    if (form.id) {
      callApi('PUT', `api/items/${form.id}`, undefined, form);
      const newItems = [
        ...items.filter(item => item.id !== form.id),
        { ...form },
      ];
      this.processItems(weekStartDate, newItems);
    } else {
      const id = await callApi('POST', 'api/items', undefined, form);

      // Place current top behing this
      let newItems;
      if (!form.date) {
        // Backlog items get ordered
        const oldTop = items.find(
          item => !item.order_prev && !item.date && item.type === form.type,
        );
        newItems = [
          ...items.filter(item => !oldTop || item.id !== oldTop.id),
          { ...form, id },
        ];
        oldTop && newItems.push({ ...oldTop, order_prev: id });
      } else {
        // Do not order date or typed items, just add it
        newItems = [...items, { ...form, id }];
      }
      this.processItems(weekStartDate, newItems);
    }

    this.closeForm();
  }

  async saveItem(newItem, preserveState) {
    if (newItem.id) {
      if (!preserveState) {
        const items = [
          ...this.state.items.filter(item => item.id !== newItem.id),
          { ...newItem },
        ];
        this.processItems(this.state.weekStartDate, items);
      }
      return callApi('PUT', `api/items/${newItem.id}`, undefined, {
        ...newItem,
        no_socket: preserveState,
      });
    }
  }

  useSuggestion(item) {
    const { form } = this.state;
    const update = {
      title: item.title,
      description: item.description,
      data: { ...form.data, suggestionId: item.id },
    };
    this.setState({
      form: { ...form, ...update },
      showSuggestions: false,
      showMobileSearchResults: false,
    });
  }

  setShowMobileSearchResults(show) {
    this.setState({ showMobileSearchResults: show });
  }

  getSuggestions(title) {
    this.setState({ loadingSuggestions: true });
    const query = utils.escapeURI(title);
    callApi('GET', `api/suggestions/q/${query}`).then(items => {
      this.setState({
        suggestions: items,
        loadingSuggestions: false,
        showMobileSearchResults: true,
      });
    });
  }

  getRandomSuggestion() {
    callApi('GET', `api/suggestions/random`).then(suggestion => {
      this.setState({ suggestion });
    });
  }

  inputChange(e) {
    const { form } = this.state;
    const update = { [e.target.name]: e.target.value };
    if (
      e.target.name === 'assigned_to_id' &&
      this.userName(+e.target.value) === BOT
    ) {
      update.send_invite = false;
    }
    this.setState({ form: { ...form, ...update, dirty: true } });

    if (update.title) {
      if (update.title.length > 1) {
        const query = utils.escapeURI(update.title);
        clearTimeout(this.historySearchTimer);
        this.historySearchTimer = setTimeout(
          () =>
            callApi('GET', `api/items/q/${query}`).then(items => {
              this.setState({
                historicalSuggestions: items,
                showMobileSearchResults: true,
              });
            }),
          200,
        );
      } else {
        this.setState({ historicalSuggestions: [] });
      }
    }
    if (update.title) {
      if (update.title.length > 1) {
        this.setState({ loadingSuggestions: true });
        clearTimeout(this.suggestionTimer);
        this.suggestionTimer = setTimeout(
          () => this.getSuggestions(update.title),
          200,
        );
      } else {
        this.setState({ suggestions: [] });
      }
    }
  }

  statusChange(e) {
    if (e.target.name === COMPLETABLE) {
      this.setState({
        form: {
          ...this.state.form,
          status: e.target.checked ? COMPLETABLE : undefined,
        },
      });
    } else if (e.target.name === 'status') {
      this.setState({
        form: {
          ...this.state.form,
          status: e.target.checked ? COMPLETE : COMPLETABLE,
        },
      });
    }
  }

  checkboxChange(e) {
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.checked },
    });
  }

  dateChange(date) {
    const update = date
      ? { date: moment(date).format(DATE_FORMAT) }
      : { date: undefined, time: undefined };
    this.setState({
      form: { ...this.state.form, ...update },
    });
  }

  timeChange(newTime) {
    const {
      form,
      form: { time, end_time },
    } = this.state;
    const timeOnly = newTime ? moment(newTime).format('HH:mm') : undefined;
    const newEndTime =
      timeOnly && end_time && utils.followTime(end_time, time, timeOnly);

    this.setState({
      form: {
        ...form,
        time: timeOnly,
        end_time: newEndTime ? newEndTime : end_time,
      },
    });
  }

  endTimeChange(time) {
    const timeOnly = time ? moment(time).format('HH:mm') : undefined;
    this.setState({ form: { ...this.state.form, end_time: timeOnly } });
  }

  /** Checkbox on card changed - udpate item and save*/
  cardMarkComplete(item, e) {
    const { weekStartDate, items } = this.state;
    const status = e.target.checked ? COMPLETE : COMPLETABLE;
    callApi('PUT', `api/items/${item.id}`, undefined, { ...item, status });
    const newItems = [
      ...items.filter(found => found.id !== item.id),
      { ...item, status },
    ];
    this.processItems(weekStartDate, newItems);
  }

  setWeekStart(newDate) {
    this.refreshItems(newDate);
    this.setState({ weekStartDate: newDate });
  }

  weekForward() {
    const newDate = moment(this.state.weekStartDate)
      .add(7, 'days')
      .format(DATE_FORMAT);
    this.setWeekStart(newDate);
  }

  weekBack() {
    const newDate = moment(this.state.weekStartDate)
      .add(-7, 'days')
      .format(DATE_FORMAT);
    this.setWeekStart(newDate);
  }

  async weekToday() {
    const newDate = moment().startOf('isoWeek').format(DATE_FORMAT);
    await this.setWeekStart(newDate);
    this.setState({ scrollToToday: true });
  }

  async toggleShowCompleteInBacklog() {
    const { showCompleteInBacklog } = this.state;
    await this.setState({ showCompleteInBacklog: !showCompleteInBacklog });
    this.processItems();
  }

  userName(userId) {
    const { auth } = this.props;
    const user = auth.users.find(user => user.id === userId);
    return user && user.name;
  }

  isMobile() {
    return window.innerWidth <= 768;
  }

  convertDay(day) {
    return day === 0 ? 6 : day - 1;
  }

  render() {
    const {
      items,
      itemsByDay,
      form,
      suggestions,
      loadingSuggestions,
      historicalSuggestions,
      weekStartDate,
      scrollToToday,
      open,
      planner2Open,
      showMobileForm,
      backlog,
      showCompleteInBacklog,
      showSuggestions,
      showMobileSearchResults,
      suggestion,
    } = this.state;
    const { auth, location } = this.props;
    const isMobile = this.isMobile();
    const pathParts = idx(location, _ => _.pathname.split('/'));
    const activePage = pathParts && pathParts[2];

    const inboxUnread = backlog.filter(item => !item.read_at && !item.type)
      .length;
    const files = items.filter(item => item.type === TYPE_FILE);
    const filesUnread = files.filter(item => !item.read_at).length;
    return (
      <div
        className={classNames('Home', {
          'modal-open': showMobileForm || planner2Open,
        })}
      >
        {showMobileForm && (
          <EditFormMobile
            form={form}
            historicalSuggestions={historicalSuggestions}
            suggestions={suggestions}
            showSuggestions={showSuggestions}
            showMobileSearchResults={showMobileSearchResults}
            setShowMobileSearchResults={e => this.setShowMobileSearchResults(e)}
            loadingSuggestions={loadingSuggestions}
            users={auth && auth.users}
            saveAndCloseForm={e => this.saveAndCloseForm(e)}
            deleteAndCloseForm={() => this.deleteAndCloseForm()}
            inputChange={e => this.inputChange(e)}
            useSuggestion={e => this.useSuggestion(e)}
            checkboxChange={e => this.checkboxChange(e)}
            statusChange={e => this.statusChange(e)}
            dateChange={date => this.dateChange(date)}
            timeChange={time => this.timeChange(time)}
            endTimeChange={time => this.endTimeChange(time)}
            closeForm={() => this.closeForm()}
          />
        )}
        <EditFormModal
          auth={auth}
          open={open}
          form={form}
          historicalSuggestions={historicalSuggestions}
          suggestions={suggestions}
          showSuggestions={showSuggestions}
          showMobileSearchResults={showMobileSearchResults}
          setShowMobileSearchResults={e => this.setShowMobileSearchResults(e)}
          loadingSuggestions={loadingSuggestions}
          users={auth && auth.users}
          saveAndCloseForm={e => this.saveAndCloseForm(e)}
          deleteAndCloseForm={() => this.deleteAndCloseForm()}
          inputChange={e => this.inputChange(e)}
          useSuggestion={e => this.useSuggestion(e)}
          checkboxChange={e => this.checkboxChange(e)}
          statusChange={e => this.statusChange(e)}
          dateChange={date => this.dateChange(date)}
          timeChange={time => this.timeChange(time)}
          endTimeChange={time => this.endTimeChange(time)}
          closeForm={() => this.closeForm()}
          isMobile={() => this.isMobile()}
        />

        <Planner2
          open={planner2Open}
          onRequestClose={() => this.closePlanner2()}
          onNewPlannerItems={newItems => this.onNewPlannerItems(newItems)}
          users={auth && auth.users}
          weekStartDate={weekStartDate}
          weekToday={() => this.weekToday()}
          weekBack={() => this.weekBack()}
          weekForward={() => this.weekForward()}
        />
        <SubNav
          inboxUnread={inboxUnread}
          filesUnread={filesUnread}
          weekStartDate={weekStartDate}
          showCompleteInBacklog={showCompleteInBacklog}
          weekToday={() => this.weekToday()}
          weekBack={() => this.weekBack()}
          weekForward={() => this.weekForward()}
          openPlanner2={() => this.openPlanner2()}
          toggleShowCompleteInBacklog={() => this.toggleShowCompleteInBacklog()}
        />
        <Switch>
          <Route
            path="/home/week"
            render={() => (
              <Dashboard
                auth={auth}
                itemsByDay={itemsByDay}
                weekStartDate={weekStartDate}
                scrollToToday={scrollToToday}
                open={open}
                showMobileForm={showMobileForm}
                form={form}
                historicalSuggestions={historicalSuggestions}
                suggestions={suggestions}
                showSuggestions={showSuggestions}
                showMobileSearchResults={showMobileSearchResults}
                planner2Open={planner2Open}
                loadingSuggestions={loadingSuggestions}
                users={auth && auth.users}
                setScrollToToday={scrollToToday =>
                  this.setState({ scrollToToday })
                }
                setShowMobileSearchResults={e =>
                  this.setShowMobileSearchResults(e)
                }
                saveAndCloseForm={e => this.saveAndCloseForm(e)}
                deleteAndCloseForm={() => this.deleteAndCloseForm()}
                inputChange={e => this.inputChange(e)}
                useSuggestion={e => this.useSuggestion(e)}
                checkboxChange={e => this.checkboxChange(e)}
                statusChange={e => this.statusChange(e)}
                dateChange={date => this.dateChange(date)}
                timeChange={time => this.timeChange(time)}
                endTimeChange={time => this.endTimeChange(time)}
                cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                closeForm={() => this.closeForm()}
                weekBack={() => this.weekBack()}
                weekForward={() => this.weekForward()}
                weekToday={() => this.weekToday()}
                openForm={(day, index) => this.openForm(day, index)}
                userName={id => this.userName(id)}
              />
            )}
          />
          <Route
            path="/home/inbox"
            render={() => (
              <Inbox
                auth={auth}
                backlog={backlog}
                open={open}
                showMobileForm={showMobileForm}
                showCompleteInBacklog={showCompleteInBacklog}
                form={form}
                historicalSuggestions={historicalSuggestions}
                suggestions={suggestions}
                showSuggestions={showSuggestions}
                showMobileSearchResults={showMobileSearchResults}
                loadingSuggestions={loadingSuggestions}
                users={auth && auth.users}
                setShowMobileSearchResults={e =>
                  this.setShowMobileSearchResults(e)
                }
                saveAndCloseForm={e => this.saveAndCloseForm(e)}
                deleteAndCloseForm={() => this.deleteAndCloseForm()}
                saveItem={(item, preserveState) =>
                  this.saveItem(item, preserveState)
                }
                moveTo={(id, prevId) => this.moveTo(id, prevId)}
                inputChange={e => this.inputChange(e)}
                useSuggestion={e => this.useSuggestion(e)}
                checkboxChange={e => this.checkboxChange(e)}
                statusChange={e => this.statusChange(e)}
                dateChange={date => this.dateChange(date)}
                timeChange={time => this.timeChange(time)}
                endTimeChange={time => this.endTimeChange(time)}
                cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                closeForm={() => this.closeForm()}
                openForm={(day, index, prefillOpts) =>
                  this.openForm(day, index, prefillOpts)
                }
                userName={id => this.userName(id)}
              />
            )}
          />
          <Route
            path="/home/files"
            render={() => (
              <Files
                auth={auth}
                files={files}
                open={open}
                showMobileForm={showMobileForm}
                showCompleteInBacklog={showCompleteInBacklog}
                form={form}
                historicalSuggestions={historicalSuggestions}
                suggestions={suggestions}
                showSuggestions={showSuggestions}
                showMobileSearchResults={showMobileSearchResults}
                loadingSuggestions={loadingSuggestions}
                users={auth && auth.users}
                setShowMobileSearchResults={e =>
                  this.setShowMobileSearchResults(e)
                }
                saveAndCloseForm={e => this.saveAndCloseForm(e)}
                deleteAndCloseForm={() => this.deleteAndCloseForm()}
                saveItem={(item, preserveState) =>
                  this.saveItem(item, preserveState)
                }
                moveTo={(id, prevId) => this.moveTo(id, prevId)}
                inputChange={e => this.inputChange(e)}
                useSuggestion={e => this.useSuggestion(e)}
                checkboxChange={e => this.checkboxChange(e)}
                statusChange={e => this.statusChange(e)}
                dateChange={date => this.dateChange(date)}
                timeChange={time => this.timeChange(time)}
                endTimeChange={time => this.endTimeChange(time)}
                cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                closeForm={() => this.closeForm()}
                openForm={(day, index, prefillOpts) =>
                  this.openForm(day, index, prefillOpts)
                }
                userName={id => this.userName(id)}
              />
            )}
          />
          <Route
            render={() => (
              <Summary
                backlog={backlog}
                suggestion={suggestion}
                todayItems={
                  itemsByDay
                    ? itemsByDay[this.convertDay(moment().day())].filter(
                        ({ id }) => !!id,
                      )
                    : []
                }
                setWeekToday={() => this.weekToday()}
                cardMarkComplete={(item, e) => this.cardMarkComplete(item, e)}
                openForm={(dayIndex, itemId) => this.openForm(dayIndex, itemId)}
                openFormToToday={prefillOpts =>
                  this.openForm(this.convertDay(moment().day()), 0, prefillOpts)
                }
                userName={id => this.userName(id)}
                getRandomSuggestion={() => this.getRandomSuggestion()}
                openPlanner2={() => this.openPlanner2()}
              />
            )}
          />
        </Switch>
        {isMobile && (
          <AddNewButton
            icon={BigPlusIcon}
            onClick={() =>
              this.openForm(-1, 0, {
                type: activePage === 'files' ? TYPE_FILE : undefined,
              })
            }
          />
        )}
        {!isMobile && (
          <Button
            variant="small"
            style={{ position: 'fixed', top: 16, right: 16, zIndex: 3 }}
            onClick={() =>
              this.openForm(-1, 0, {
                type: activePage === 'files' ? TYPE_FILE : undefined,
              })
            }
          >
            <box-icon name="plus" />
            New
          </Button>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  {},
)(Home);
