const SCROLL_REQUEST = 'SCROLL_REQUEST';
const RESET_SCROLL_REQUEST = 'RESET_SCROLL_REQUEST';
const initialState = true;

export function requestScroll() {
  return { type: SCROLL_REQUEST };
}

export function resetScrollRequest() {
  return { type: RESET_SCROLL_REQUEST };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === SCROLL_REQUEST) {
    result = true;
  } else if (action.type === RESET_SCROLL_REQUEST) {
    result = false;
  }
  return result;
}
