import React from 'react';
import * as styles from '../../../styles';
import { HistoricalSuggestions, HistoricalItem } from '../Components';

export default class HistoricalSearchResults extends React.Component {
  render() {
    const { historicalSuggestions, useSuggestion } = this.props;

    return (
      <HistoricalSuggestions {...this.props}>
        {historicalSuggestions.map((item, index) => (
          <HistoricalItem key={index} onClick={() => useSuggestion(item)}>
            <div style={{ fontWeight: 'bold' }}>{item.title}</div>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: styles.Gray,
              }}
            >
              {item.description}
            </div>
          </HistoricalItem>
        ))}
      </HistoricalSuggestions>
    );
  }
}
