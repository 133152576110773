import './UserColors.css';

export default class UserColors {
  static getUserIndex(users, userId) {
    for (var i = 0; i < users.length; i++) {
      if (users[i].id === userId) {
        return i;
      }
    }
    return null;
  }

  static getUserBgClass(users, userId) {
    const userIndex = this.getUserIndex(users, userId);
    if (userIndex === null) {
      return 'userNoneBg';
    } else {
      return 'user' + userIndex + 'Bg';
    }
  }

  static getCheckboxBgClass(users, userId) {
    const userIndex = this.getUserIndex(users, userId);
    if (userIndex === null) {
      return 'userNoneCheckboxBg';
    } else {
      return 'user' + userIndex + 'CheckboxBg';
    }
  }

  static getCardBgClass(users, userId) {
    const userIndex = this.getUserIndex(users, userId);
    if (userIndex === null) {
      return 'userNoneCardBg';
    } else {
      return 'user' + userIndex + 'CardBg';
    }
  }
}
