import React from 'react';
import { connect } from 'react-redux';
import 'boxicons';
import { H1, H2, H3 } from '../components/Type';
import { Card, CardBody, CardFooterLink } from '../components/Card';
import { Button } from '../components/Button';
import { Level } from '../components/Level';
import { Item } from '../components/Item';
import { TodayEmptyState, InboxEmptyState } from '../components/EmptyState';
import sortByOrder from '../Inbox/sort';
import { getBotUser } from '../utils';
import { COMPLETE } from '../constants';
import './Summary.scss';

const Summary = props => {
  const {
    auth,
    backlog,
    suggestion,
    setWeekToday,
    todayItems,
    cardMarkComplete,
    openForm,
    openFormToToday,
    userName,
    getRandomSuggestion,
    openPlanner2,
  } = props;

  const memoSetWeekToday = React.useCallback(setWeekToday, []);
  const botUserId = getBotUser(auth);

  React.useEffect(() => {
    memoSetWeekToday();
  }, [memoSetWeekToday]);

  const inbox = sortByOrder(backlog)
    .filter(
      item =>
        item.status !== COMPLETE &&
        (!item.assigned_to_id || item.assigned_to_id === botUserId),
    )
    .slice(0, 3);

  return (
    <div className="Summary">
      <Level variant="start">
        <H1>
          <span role="img" aria-label="hello"></span>👋 Hello {auth.name}!
        </H1>
        <Button variant="small hide-mobile" onClick={openPlanner2}>
          Plan Next Week
        </Button>
      </Level>

      <div className="summary-cols">
        <div className="left-col">
          <Card>
            <CardBody>
              <Level>
                <H2>Here&rsquo;s what&rsquo;s up today:</H2>
                <Button variant="clear" onClick={() => openFormToToday({})}>
                  <box-icon name="plus" />
                </Button>
              </Level>
            </CardBody>
            {todayItems.map(item => (
              <Item
                key={item.id}
                item={item}
                auth={auth}
                hideUnreadBadge={true}
                openForm={openForm}
                cardMarkComplete={cardMarkComplete}
                userName={userName}
              />
            ))}
            {!todayItems.length && <TodayEmptyState />}
            <CardFooterLink to="/home/week">
              Go to week schedule
              <box-icon name="right-arrow-alt" />
            </CardFooterLink>
          </Card>
        </div>
        <div className="right-col">
          <Card>
            <CardBody>
              <Level>
                <H2>Idea Machine</H2>
                <Button variant="clear" onClick={getRandomSuggestion}>
                  <box-icon type="solid" name="magic-wand" />
                </Button>
              </Level>

              <p>Need a spark of inspiration? How about&hellip;</p>
              {suggestion && (
                <Level
                  variant="media-object"
                  onClick={() =>
                    openFormToToday({
                      title: suggestion.title,
                      description: suggestion.description,
                      data: { suggestionId: suggestion.id },
                    })
                  }
                >
                  <img alt="suggestion" src={suggestion.image_url} />
                  <div className="media-object-text">
                    <H3>{suggestion.title}</H3>
                    <p>{suggestion.description}</p>
                  </div>
                </Level>
              )}
              {!suggestion && <p>loading...</p>}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Level>
                <H2>Inbox</H2>
                <Button variant="clear" onClick={openForm}>
                  <box-icon name="plus" />
                </Button>
              </Level>
            </CardBody>
            {inbox.map(item => (
              <Item
                key={item.id}
                item={item}
                auth={auth}
                openForm={openForm}
                cardMarkComplete={cardMarkComplete}
                userName={userName}
              />
            ))}
            {!inbox.length && <InboxEmptyState />}
            <CardFooterLink to="/home/inbox">
              Go to Inbox
              <box-icon name="right-arrow-alt" />
            </CardFooterLink>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({ auth: state.auth }), {})(Summary);
