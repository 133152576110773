const LOADING_SET = 'LOADING_SET';
const initialState = false;

export function setLoading(isLoading) {
  return { type: LOADING_SET, isLoading };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === LOADING_SET) {
    result = action.isLoading;
  }
  return result;
}
