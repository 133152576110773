const TAB_SET = 'TAB_SET';
const initialState = 'activity';

export function setTab(tab) {
  return { type: TAB_SET, tab };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === TAB_SET) {
    result = action.tab;
  }
  return result;
}
