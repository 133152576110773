import moment from 'moment';

const ITEMS_SET = 'ITEMS_SET';
const ITEMS_ADD = 'ITEMS_ADD';
const ITEMS_UPDATE = 'ITEMS_UPDATE';
const initialState = [];

export function set(items) {
  return { type: ITEMS_SET, items };
}

export function add(item) {
  return { type: ITEMS_ADD, item };
}

export function update(item) {
  return { type: ITEMS_UPDATE, item };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === ITEMS_SET) {
    result = action.items;
  } else if (action.type === ITEMS_ADD) {
    result = [...state, action.item];
  } else if (action.type === ITEMS_UPDATE) {
    const allBut = state.filter(existing => existing.id !== action.item.id);
    const udpatedList = [...allBut, action.item].sort(
      (a, b) => moment(a.created_at) - moment(b.created_at),
    );
    result = udpatedList;
  }
  return result;
}
