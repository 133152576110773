import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import * as styles from '../../../styles';
import * as utils from '../utils';
import {
  Select,
  Button,
  FormButton,
  MobileFormField,
  TextInput,
  TextAreaInput,
  Checkbox,
} from '../Components';
import SearchResultsMobile from './SearchResultsMobile';
import TitleIcon from '../../Icons/Title.png';
import DescriptionIcon from '../../Icons/Description.png';
import DateIcon from '../../Icons/Date.png';
import AssigneeIcon from '../../Icons/Assignee.png';
import LocationIcon from '../../Icons/Location.png';
import XIcon from '../../Icons/BigX.png';
import LinkIcon from '../../Icons/Link.png';
import WhiteCheckIcon from '../../Icons/WhiteCheck.png';
import CategoryIcon from '../../Icons/Category.png';

import 'react-datepicker/dist/react-datepicker.css';
import '../react-datepicker.css';

import { BOT, COMPLETE, COMPLETABLE, CATEGORIES } from '../constants';

export default class EditFormMobile extends React.Component {
  state = {
    showHistory: true,
    keyboardOpen: true,
  };

  componentDidMount() {
    new Image().src = TitleIcon;
    new Image().src = DescriptionIcon;
    new Image().src = DateIcon;
    new Image().src = AssigneeIcon;
    new Image().src = LocationIcon;
    const date = document.getElementById('date-picker-input');
    date && date.setAttribute('readonly', 'readonly');
    const time = document.getElementById('time-picker-input');
    time && time.setAttribute('readonly', 'readonly');
    const endTime = document.getElementById('end-time-picker-input');
    endTime && endTime.setAttribute('readonly', 'readonly');
  }

  moreDetails() {
    this.setState({ showMoreDetails: true });
  }

  lessDetails() {
    this.setState({ showMoreDetails: false });
  }

  render() {
    const {
      form,
      historicalSuggestions,
      suggestions,
      showMobileSearchResults,
      setShowMobileSearchResults,
      users,
      saveAndCloseForm,
      deleteAndCloseForm,
      inputChange,
      checkboxChange,
      dateChange,
      timeChange,
      endTimeChange,
      statusChange,
      closeForm,
    } = this.props;
    const { showHistory, keyboardOpen } = this.state;
    const links = utils.getLinks(form.description);

    const assignedToBot = !!users.find(
      user => user.id === +form.assigned_to_id && user.name === 'bot',
    );
    const showHistorical =
      form.title &&
      form.title.length > 1 &&
      showHistory &&
      showMobileSearchResults &&
      ((historicalSuggestions && historicalSuggestions.length > 0) ||
        (suggestions && suggestions.length > 0));

    const time = form.time && moment(form.date + ' ' + form.time).valueOf();
    const endTime =
      (form.end_time && moment(form.date + ' ' + form.end_time).valueOf()) ||
      time;

    return (
      <form
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        action="submit"
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: styles.White,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: 101,
        }}
      >
        <div style={{ alignSelf: 'flex-start' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                margin: 16,
                fontFamily: styles.FontFamilyCooper,
                fontSize: 22,
                padding: 0,
              }}
            >
              {form.id ? 'Edit Item' : 'New Item'}
            </div>
            <img
              src={XIcon}
              alt="X"
              style={{
                cursor: 'pointer',
                width: 24,
                height: 24,
                paddingTop: 20,
                marginRight: 20,
              }}
              onClick={() => closeForm()}
            />
          </div>

          <MobileFormField>
            <TextInput
              icon={TitleIcon}
              type="text"
              autoFocus
              cols="40"
              name="title"
              placeholder="Title"
              value={(form && form.title) || ''}
              onChange={e => inputChange(e)}
              ref={ref => (this.titleField = ref)}
              onFocus={() =>
                setTimeout(() => {
                  this.setState({ showHistory: true, keyboardOpen: true });
                  setShowMobileSearchResults(true);
                }, 100)
              }
              onBlur={() =>
                setTimeout(() => this.setState({ keyboardOpen: false }), 100)
              }
              onKeyDown={e => e.key === 'Enter' && saveAndCloseForm(e)}
            />
          </MobileFormField>
          {showHistorical && (
            <SearchResultsMobile form={form} {...this.props} />
          )}
          {!form.type && (
            <>
              <MobileFormField>
                <div style={{ display: 'flex' }}>
                  <div className="date" style={{ width: 'calc(100vw - 20px)' }}>
                    <DatePicker
                      id="date-picker-input"
                      placeholderText="Date"
                      selected={form.date && moment(form.date).valueOf()}
                      onChange={date => dateChange(date)}
                      dateFormat="eee, MMM dd, yyyy"
                      isClearable
                    />
                  </div>
                </div>
              </MobileFormField>

              <MobileFormField
                style={{
                  display: form.date ? 'block' : 'none',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div className="time" style={{ width: '140px' }}>
                    <DatePicker
                      id="time-picker-input"
                      placeholderText="Start Time"
                      timeCaption="Start Time"
                      selected={time}
                      onChange={time => timeChange(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="h:mm a"
                      isClearable
                    />
                  </div>
                  <div
                    style={{
                      display: form.time ? 'flex' : 'none',
                    }}
                  >
                    <React.Fragment>
                      <div
                        style={{
                          marginLeft: 20,
                          marginTop: 4,
                          paddingTop: 8,
                          width: 24,
                          color: styles.Gray,
                        }}
                      >
                        to
                      </div>
                      <div className="time" style={{ width: '140px' }}>
                        <DatePicker
                          id="end-time-picker-input"
                          placeholderText="End Time"
                          timeCaption="End Time"
                          selected={endTime}
                          onChange={time => endTimeChange(time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm a"
                          isClearable
                        />
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              </MobileFormField>
            </>
          )}
          <MobileFormField>
            <TextAreaInput
              icon={DescriptionIcon}
              rows="3"
              cols="35"
              maxRows={10}
              name="description"
              placeholder="Description"
              value={form.description || ''}
              onChange={e => inputChange(e)}
              inputRef={ref => (this.descriptionField = ref)}
              onFocus={() =>
                setTimeout(() => {
                  this.setState({ showHistory: false, keyboardOpen: true });
                }, 100)
              }
              onBlur={() => {
                setTimeout(() => this.setState({ keyboardOpen: false }), 100);
              }}
            />
          </MobileFormField>
          {links && links.length > 0 && (
            <div
              style={{
                marginLeft: 50,
                marginTop: 5,
                marginBottom: 10,
                overflowWrap: 'break-word',
              }}
            >
              {links.map((link, index) => (
                <a
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link.includes('@') ? `mailto:${link}` : link}
                  style={{
                    display: 'block',
                    color: styles.Blue,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 310,
                    background: `url(${LinkIcon}) no-repeat left/13px`,
                    textDecoration: 'none',
                    paddingLeft: 20,
                  }}
                >
                  {link}
                </a>
              ))}
            </div>
          )}
          {(this.state.showMoreDetails || form.id) && !form.type && (
            <React.Fragment>
              <MobileFormField style={{ position: 'relative' }}>
                <TextInput
                  icon={LocationIcon}
                  type="text"
                  cols="35"
                  name="location"
                  placeholder="Location"
                  value={(form && form.location) || ''}
                  onChange={e => inputChange(e)}
                  onFocus={() =>
                    setTimeout(() => {
                      this.setState({ keyboardOpen: true });
                    }, 100)
                  }
                  onBlur={() => {
                    setTimeout(
                      () => this.setState({ keyboardOpen: false }),
                      100,
                    );
                  }}
                />
                {form.location && (
                  <a
                    href={`https://maps.google.com?q=${form.location}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      position: 'absolute',
                      right: 10,
                      top: 6,
                    }}
                  >
                    {' '}
                    📌
                  </a>
                )}
              </MobileFormField>
              <MobileFormField style={{ position: 'relative' }}>
                <Select
                  icon={AssigneeIcon}
                  value={form.assigned_to_id || ''}
                  name="assigned_to_id"
                  onChange={e => inputChange(e)}
                  style={{
                    color:
                      (form.assigned_to_id === '0' || !form.assigned_to_id) &&
                      'rgb(117, 117, 117)',
                  }}
                >
                  <option value={0}>Not assigned</option>
                  {users &&
                    users
                      .filter(user => user.name !== BOT)
                      .map(user => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                </Select>
                {form.assigned_to_id > 0 && form.date && !assignedToBot && (
                  <Checkbox
                    style={{
                      position: 'absolute',
                      right: 15,
                      top: 7,
                    }}
                    icon={WhiteCheckIcon}
                  >
                    <input
                      type="checkbox"
                      onChange={e => checkboxChange(e)}
                      name="send_invite"
                      id="send_invite"
                      checked={(form && form.send_invite) || ''}
                    />
                    <label htmlFor="send_invite">Invite</label>
                  </Checkbox>
                )}
              </MobileFormField>
              <MobileFormField
                style={{ position: 'relative', padding: '10px 0' }}
              >
                <Checkbox icon={WhiteCheckIcon} style={{ left: 43 }}>
                  <input
                    type="checkbox"
                    onChange={e => statusChange(e)}
                    name="completable"
                    id="completable"
                    checked={
                      (form && [COMPLETABLE, COMPLETE].includes(form.status)) ||
                      ''
                    }
                  />
                  <label
                    style={{
                      color:
                        !form ||
                        (![COMPLETABLE, COMPLETE].includes(form.status) &&
                          'rgb(117, 117, 117)'),
                    }}
                    htmlFor="completable"
                  >
                    Task
                  </label>
                </Checkbox>

                {form && [COMPLETABLE, COMPLETE].includes(form.status) && (
                  <Checkbox icon={WhiteCheckIcon} style={{ left: 220 }}>
                    <input
                      type="checkbox"
                      onChange={e => statusChange(e)}
                      name="status"
                      id="status"
                      checked={(form && form.status === COMPLETE) || ''}
                    />
                    <label htmlFor="status">Complete</label>
                  </Checkbox>
                )}
              </MobileFormField>
              {/* CATEGORY */}
              <MobileFormField style={{ position: 'relative' }}>
                <Select
                  icon={CategoryIcon}
                  value={form.tags || ''}
                  name="tags"
                  onChange={e => inputChange(e)}
                  style={{
                    cursor: 'pointer',
                    color:
                      (form.tags === '' || !form.tags) && 'rgb(117, 117, 117)',
                    textTransform: 'capitalize',
                  }}
                >
                  <option value={''}>Category</option>
                  {CATEGORIES.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
              </MobileFormField>
            </React.Fragment>
          )}
        </div>
        <div>
          {form.errors && (
            <div style={{ color: 'red', margin: 10 }}>
              {form.errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: 10,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: keyboardOpen ? 280 : 80,
            }}
          >
            {form && form.id && (
              <Button
                style={{ paddingTop: 13 }}
                type="button"
                onClick={() => deleteAndCloseForm()}
              >
                Delete
              </Button>
            )}
            {!form.id && !this.state.showMoreDetails && !form.type && (
              <Button
                style={{ paddingTop: 10, color: styles.Gray }}
                type="button"
                onClick={() => this.moreDetails()}
              >
                More Details
                <img
                  src="/open-arrow.png"
                  alt="open"
                  style={{ height: 8, marginLeft: 4, marginBottom: 2 }}
                />
              </Button>
            )}
            {!form.id && this.state.showMoreDetails && (
              <Button
                style={{ paddingTop: 10, color: styles.Gray }}
                type="button"
                onClick={() => this.lessDetails()}
              >
                Less Details
                <img
                  src="/close-arrow.png"
                  alt="open"
                  style={{ height: 8, marginLeft: 4, marginBottom: 2 }}
                />
              </Button>
            )}
            {!form.id && form.type && <div style={{ width: '50%' }} />}
            <div>
              <FormButton type="button" onClick={() => closeForm()}>
                Cancel
              </FormButton>
              <FormButton
                variant="blue"
                style={{
                  marginLeft: 10,
                }}
                type="button"
                onClick={e => saveAndCloseForm(e)}
              >
                Save
              </FormButton>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
