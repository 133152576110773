import React from 'react';
import { connect } from 'react-redux';
import PickGroup from './PickGroup';
import * as analytics from '../../utils/analytics';
import * as scrollAction from '../../redux/scroll';

class PicksView extends React.Component {
  componentDidMount() {
    analytics.page('PicksView');
    this.maybeScroll();
  }

  componentDidUpdate() {
    this.maybeScroll();
  }

  maybeScroll() {
    const { scroll, resetScrollRequest } = this.props;
    if (scroll) {
      resetScrollRequest();
      this.scrollToTop();
    }
  }

  scrollToTop() {
    this.messagesStart && this.messagesStart.scrollIntoView();
  }

  render() {
    const { items } = this.props;
    const picks = items.filter(item => item.type === 'pick');
    return (
      <div>
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={el => {
            this.messagesStart = el;
          }}
        ></div>
        {picks &&
          picks.map(pick => (
            <PickGroup key={pick.id} items={[pick]} hideCallout={true} />
          ))}
      </div>
    );
  }
}

export default connect(
  state => ({
    scroll: state.scroll,
  }),
  {
    resetScrollRequest: scrollAction.resetScrollRequest,
  },
)(PicksView);
