import React from 'react';
import { CardBody } from './Card';
import { H3 } from './Type';
import './EmptyState.scss';

export const TodayEmptyState = () => (
  <div className="EmptyState TodayEmptyState">
    <CardBody border="both">
      <H3>
        Nothing scheduled!{' '}
        <span role="img" aria-label="dancer">
          💃🏽
        </span>
      </H3>
      <p>
        Try adding something new to do today like an art or yoga class. Or maybe
        a new dinner recipe!
      </p>
    </CardBody>
  </div>
);

export const InboxEmptyState = () => (
  <div className="EmptyState">
    <CardBody border="both">
      <H3>
        I&rsquo;m empty{' '}
        <span role="img" aria-label="envelope with arrow">
          📩
        </span>
      </H3>
      <p>
        Try adding new items by texting{' '}
        <a href="sms:206-202-0005">206-202-0005</a> or forwarding emails to:{' '}
        <a href="mailto:add@modernvillage.com">add@modernvillage.com</a>.
      </p>
    </CardBody>
  </div>
);

export const AssigneeEmptyState = () => (
  <div className="EmptyState">
    <CardBody border="both">
      <H3>
        I&rsquo;m empty{' '}
        <span role="img" aria-label="bust in silhouette">
          👤
        </span>
      </H3>
      <p>
        Try adding items by opening them up and assigning them to me or dragging
        items into this column.
      </p>
    </CardBody>
  </div>
);
