import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { callApi } from '../../utils/callApi';
import { storeLogin, clearPasswordRequired } from '../../redux/auth';
import AccountModal from './AccountModal';
import TextInput from './TextInput';

import './ChangePassword.scss';

Modal.setAppElement('#root');

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onCurrentPasswordText(e) {
    this.setState({ current: e.target.value });
  }

  onNewPasswordText(e) {
    this.setState({ password: e.target.value });
  }

  onConfirmPasswordText(e) {
    this.setState({ confirm: e.target.value });
  }

  async handleSubmitClick(e) {
    const {
      passwordRequired,
      dispatchClearPasswordRequired,
      closeForm,
    } = this.props;
    e.preventDefault();
    const errors = [];
    const { current, password, confirm } = this.state;

    if (!passwordRequired && !(current && current.length)) {
      errors.push('Please enter current password');
    }

    if (!(password && password.length)) {
      errors.push('Please enter new password');
    }

    if (password && password.localeCompare(confirm)) {
      errors.push('Password and confirm must match');
    }
    if (errors.length) {
      this.setState({ errors });
      return;
    }
    const body = { current, password };
    this.setState({ loading: true });
    const res = await callApi('PUT', 'login', undefined, body);
    this.setState({ loading: false });
    if (res === 202) {
      if (passwordRequired) {
        await dispatchClearPasswordRequired();
        this.props.history.push('/');
      }
      closeForm();
      return;
    }
    this.setState({ errors: [res.error] });
  }

  render() {
    const { passwordRequired, closeForm } = this.props;
    const { errors, loading } = this.state;
    return (
      <AccountModal
        title={passwordRequired ? 'Set Password' : 'Change Password'}
        open={true}
        closeForm={passwordRequired ? null : closeForm}
      >
        <form
          className="ChangePassword"
          onSubmit={e => this.handleSubmitClick(e)}
        >
          <div>
            {!passwordRequired && (
              <div>
                <TextInput
                  disabled={loading}
                  autoFocus={!passwordRequired}
                  type="password"
                  onChange={e => this.onCurrentPasswordText(e)}
                  placeHolder="Current Password"
                ></TextInput>
              </div>
            )}
            <div>
              <TextInput
                disabled={loading}
                autoFocus={passwordRequired}
                type="password"
                placeHolder="New Password"
                onChange={e => this.onNewPasswordText(e)}
              ></TextInput>
            </div>
            <div>
              <TextInput
                disabled={loading}
                type="password"
                placeHolder="Confirm New Password"
                onChange={e => this.onConfirmPasswordText(e)}
              ></TextInput>
            </div>
            {errors && (
              <div style={{ color: 'red' }}>
                {errors.map(error => (
                  <div key={error}>{error}</div>
                ))}
              </div>
            )}
            <div className="buttons">
              <div className="right">
                {!passwordRequired && (
                  <button
                    type="button"
                    className="button button__light-blue"
                    onClick={closeForm}
                  >
                    Cancel
                  </button>
                )}
                <button className="button button__blue" disabled={loading}>
                  Set Password
                </button>
              </div>
            </div>
          </div>
        </form>
      </AccountModal>
    );
  }
}

export default connect(() => ({}), {
  handleStoreLogin: storeLogin,
  dispatchClearPasswordRequired: clearPasswordRequired,
})(withRouter(ChangePassword));
