export const COMPLETE = 'complete';
export const COMPLETABLE = 'completable';
export const BOT = 'bot';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const CATEGORIES = [
  'general',
  'school',
  'kids-activities',
  'finances',
  'childcare',
  'health',
  'social',
  'reading-list',
  'bookmark',
  'shopping',
];

export const TYPE_FILE = 'file';
