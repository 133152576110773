import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserColor } from '../../utils/user-color';
import * as authAction from '../../redux/auth';
import './Account.scss';
import ChangePassword from './ChangePassword';

class Account extends React.Component {
  state = { showMenu: false, showPasswordForm: false };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({ showMenu: false });
  }

  render() {
    const {
      auth,
      auth: { password_required: authPasswordRequired },
      product,
    } = this.props;
    const { showMenu, showPasswordForm } = this.state;
    const showSetPasswordForm = ['password', 'signup'].includes(product);
    const passwordRequired = authPasswordRequired || showSetPasswordForm;
    const header = (
      <>
        <div
          className="disk"
          style={{
            backgroundColor: getUserColor(auth.users, auth.id),
          }}
        >
          {auth && auth.name.substring(0, 2)}
        </div>
        <div>
          <div className="family-name truncate">{auth.family}</div>
          <div className="name truncate">{auth.name}</div>
          <div className="product truncate">{product || 'home'}</div>
        </div>
      </>
    );

    return (
      <div className="Account">
        <div
          className="heading"
          onClick={() => this.setState({ showMenu: !showMenu })}
        >
          {header}
        </div>
        {!showPasswordForm && (
          <div
            className="modal"
            style={{
              display: showMenu ? undefined : 'none',
            }}
          >
            <div className="modal-menu" ref={node => (this.node = node)}>
              <div className="header">
                {header}
                <div className="x">
                  <box-icon
                    name="x"
                    onClick={() => this.setState({ showMenu: false })}
                  />
                </div>
              </div>
              <button
                className="button__password"
                onClick={() =>
                  this.setState({ showPasswordForm: true, showMenu: !showMenu })
                }
              >
                <box-icon name="key" />
                <div className="link-title">Change Password</div>
              </button>
              <Link
                to="/calendar"
                onClick={() => this.setState({ showMenu: !showMenu })}
              >
                <button
                  className="button__calendar"
                  onClick={() => this.setState({ showMenu: !showMenu })}
                >
                  <box-icon name="calendar-alt" />
                  <div className="link-title">Manage Calendars</div>
                </button>
              </Link>
              <button
                className="button__logout"
                onClick={this.props.handleLogout}
              >
                <box-icon name="log-out" />
                <div className="link-title">Sign Out</div>
              </button>
            </div>
          </div>
        )}
        {(passwordRequired || showPasswordForm) && (
          <ChangePassword
            passwordRequired={passwordRequired}
            closeForm={() => this.setState({ showPasswordForm: false })}
          />
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  {
    handleLogout: authAction.reset,
  },
)(Account);
