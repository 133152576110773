import React from 'react';
import queryString from 'query-string';
import { callApi } from '../../utils/callApi';
import { Button } from '../Home/components/Button';
import Calendar from './Calendar';
import AccountModal from '../Account/AccountModal';

import './ConnectCalendars.scss';

export default class ConnectCalendar extends React.Component {
  state = {
    loading: false,
    connections: [],
  };

  async componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.code) {
      await this.authenticate(params.code);
      this.props.history.push(`/calendar`);
    } else {
      this.loadConnections();
    }
  }

  async authenticate(code) {
    this.setState({ loading: true });
    const url = 'api/gcal/auth';
    await callApi('POST', url, undefined, { code });
    await this.loadConnections();
    this.setState({ loading: false });
  }

  async loadConnections() {
    this.setState({ loading: true });
    await Promise.all([
      this.getCalendarConnections(),
      this.getPrimaryCalendar(),
    ]);
    this.setState({ loading: false });
  }

  async getCalendarConnections() {
    const connections = await callApi('GET', 'api/gcal/connections');
    this.setState({ connections });
  }

  async getPrimaryCalendar() {
    const res = await callApi('GET', 'api/gcal/primary');
    this.setState({
      primaryConnectionId: res.calendar_id,
      primaryCalendarId: res.google_calendar_id,
    });
  }

  async handleConnectClick() {
    this.setState({ loading: true });
    const res = await callApi('GET', 'api/gcal/auth');
    if (res.auth_url) {
      window.location.href = res.auth_url;
    }
  }

  async handleRemoveConnection(connectionId) {
    const confirm = window.confirm(
      'Are you use you want to disconnect this calendar?',
    );
    if (!confirm) {
      return;
    }
    await this.handleSetPrimary(null, null);
    await callApi('DELETE', `api/gcal/connections/${connectionId}`);
    this.loadConnections();
  }

  async handleSetPrimary(connectionId, calendarId) {
    const body = { calendar_id: connectionId, google_calendar_id: calendarId };
    const res = await callApi('POST', 'api/gcal/primary', undefined, body);
    if (res === 200) {
      await this.setState({
        primaryConnectionId: connectionId,
        primaryCalendarId: calendarId,
      });
    }
  }

  onClose() {
    this.props.history.push(`/`);
  }

  render() {
    const {
      connections = [],
      loading,
      primaryConnectionId,
      primaryCalendarId,
    } = this.state;
    return (
      <AccountModal
        title="Calendar"
        open={true}
        closeForm={() => this.onClose()}
      >
        {loading && 'Loading...'}
        <div className="ConnectCalendars">
          {!loading && !connections.length && (
            <div className="connect-panel">
              <div className="copy">
                Connect a Google Calendar to Modern Village
              </div>
              <Button onClick={() => this.handleConnectClick()}>Connect</Button>
            </div>
          )}
          {!loading &&
            connections.map(connection => (
              <div className="calendar-line" key={connection.id}>
                <Calendar
                  connectionId={connection.id}
                  primaryConnectionId={primaryConnectionId}
                  primaryCalendarId={primaryCalendarId}
                  setPrimary={this.handleSetPrimary.bind(this)}
                />
                {primaryCalendarId && (
                  <Button
                    disabled={loading}
                    type="button"
                    onClick={() => this.handleRemoveConnection(connection.id)}
                  >
                    Disconnect
                  </Button>
                )}
              </div>
            ))}
          <div className="close-button-line">
            <Button
              style={{ backgroundColor: '#3D835E', borderColor: '#3D835E' }}
              disabled={loading}
              type="button"
              onClick={() => this.onClose()}
            >
              Close
            </Button>
          </div>
        </div>
      </AccountModal>
    );
  }
}
