import React from 'react';
import { H1, H3 } from '../Home/components/Type';
import { Button } from '../Home/components/Button';
import FakeAddButton from '../FakeAddButton/FakeAddButton';

import './Library.scss';

const Library = () => (
  <div className="Library">
    <div className="content">
      <H3>Coming Soon</H3>
      <H1>A Place to Learn</H1>
      <p
        style={{
          color: '#666',
          margin: '10px auto 20px auto',
          maxWidth: '375px',
        }}
      >
        Find the best practices of how other parents have figured out the things
        you&rsquo;re about to tackle.
      </p>
      <Button>
        <a
          href="https://themodernvillage.typeform.com/to/dDqtT9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request Early Access
        </a>
      </Button>
    </div>
    <FakeAddButton />
  </div>
);
export default Library;
