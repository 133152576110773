import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import * as styles from '../../../styles';
import * as utils from '../utils';
import {
  Select,
  FormField,
  FormButton,
  TextInput,
  TextAreaInput,
  Checkbox,
} from '../Components';
import HistoricalSearchResults from './HistoricalSearchResults';
import TitleIcon from '../../Icons/Title.png';
import DescriptionIcon from '../../Icons/Description.png';
import DateIcon from '../../Icons/Date.png';
import AssigneeIcon from '../../Icons/Assignee.png';
import LocationIcon from '../../Icons/Location.png';
import XIcon from '../../Icons/BigX.png';
import LinkIcon from '../../Icons/Link.png';
import WhiteCheckIcon from '../../Icons/WhiteCheck.png';
import CategoryIcon from '../../Icons/Category.png';

import 'react-datepicker/dist/react-datepicker.css';
import '../react-datepicker.css';

import {
  BOT,
  COMPLETE,
  COMPLETABLE,
  CATEGORIES,
  TYPE_FILE,
} from '../constants';

export default class EditForm extends React.Component {
  state = {
    showHistory: true,
    showLinks: false,
  };

  componentDidMount() {
    new Image().src = TitleIcon;
    new Image().src = DescriptionIcon;
    new Image().src = DateIcon;
    new Image().src = AssigneeIcon;
    new Image().src = LocationIcon;
  }

  render() {
    const {
      form,
      historicalSuggestions,
      users,
      saveAndCloseForm,
      deleteAndCloseForm,
      inputChange,
      useSuggestion,
      checkboxChange,
      dateChange,
      timeChange,
      endTimeChange,
      statusChange,
      closeForm,
    } = this.props;
    const { showHistory, showLinks } = this.state;

    const assignedToBot = !!users.find(
      user => user.id === +form.assigned_to_id && user.name === 'bot',
    );
    const links = utils.getLinks(form.description);
    const showHistorical =
      showHistory &&
      historicalSuggestions &&
      historicalSuggestions.length > 0 &&
      form.title.length > 1;

    const time = form.time && moment(form.date + ' ' + form.time).valueOf();
    const endTime =
      (form.end_time && moment(form.date + ' ' + form.end_time).valueOf()) ||
      time;
    return (
      <form autoComplete="off" onSubmit={e => saveAndCloseForm(e)}>
        <div>
          <img
            src={XIcon}
            alt="X"
            style={{
              cursor: 'pointer',
              position: 'relative',
              top: -24,
              left: 368,
              width: 24,
              height: 24,
            }}
            onClick={() => closeForm()}
          />
          <div style={{ marginTop: -9 }}>
            {/* TITLE */}
            <FormField>
              <TextInput
                icon={TitleIcon}
                type="text"
                autoFocus
                cols="40"
                name="title"
                placeholder="Title"
                value={(form && form.title) || ''}
                onChange={e => inputChange(e)}
                onFocus={() =>
                  setTimeout(() => this.setState({ showHistory: true }), 100)
                }
                onBlur={() =>
                  setTimeout(() => this.setState({ showHistory: false }), 100)
                }
              />
            </FormField>
            {showHistorical && (
              <HistoricalSearchResults
                historicalSuggestions={historicalSuggestions}
                useSuggestion={useSuggestion}
              />
            )}

            {/* DATE */}
            {!form.type && (
              <FormField>
                <div className="date" style={{ width: '388px' }}>
                  <DatePicker
                    placeholderText="Date"
                    selected={form.date && moment(form.date).valueOf()}
                    onChange={date => dateChange(date)}
                    dateFormat="eee, MMM dd, yyyy"
                    isClearable
                  />
                </div>
              </FormField>
            )}

            {/* TIME */}
            {!form.type && form.date && (
              <FormField>
                <div style={{ display: 'flex' }}>
                  <div className="time" style={{ width: '150px' }}>
                    <DatePicker
                      placeholderText="Start Time"
                      timeCaption="Start Time"
                      selected={time}
                      onChange={time => timeChange(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="h:mm a"
                      isClearable
                    />
                  </div>
                  {form.time && (
                    <React.Fragment>
                      <div
                        style={{
                          marginLeft: 16,
                          marginTop: 9,
                          width: 28,
                          color: styles.Gray,
                        }}
                      >
                        to
                      </div>
                      <div className="time" style={{ width: '150px' }}>
                        <DatePicker
                          placeholderText="End Time"
                          timeCaption="End Time"
                          selected={endTime}
                          onChange={time => endTimeChange(time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm a"
                          isClearable
                        />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </FormField>
            )}

            {/* ASSIGN */}
            {!form.type && (
              <FormField style={{ position: 'relative' }}>
                <Select
                  icon={AssigneeIcon}
                  value={form.assigned_to_id || ''}
                  name="assigned_to_id"
                  onChange={e => inputChange(e)}
                  style={{
                    cursor: 'pointer',
                    color:
                      (form.assigned_to_id === '0' || !form.assigned_to_id) &&
                      'rgb(117, 117, 117)',
                  }}
                >
                  <option value={0}>Not assigned</option>
                  {users &&
                    users
                      .filter(user => user.name !== BOT)
                      .map(user => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                </Select>
                {form.assigned_to_id > 0 && form.date && !assignedToBot && (
                  <Checkbox
                    style={{
                      position: 'absolute',
                      right: 15,
                      top: 11,
                    }}
                    icon={WhiteCheckIcon}
                  >
                    <input
                      type="checkbox"
                      onChange={e => checkboxChange(e)}
                      name="send_invite"
                      id="send_invite"
                      checked={(form && form.send_invite) || ''}
                    />
                    <label htmlFor="send_invite">Invite</label>
                  </Checkbox>
                )}
              </FormField>
            )}

            {/* TASK */}
            {!form.type && (
              <>
                <FormField style={{ position: 'relative', padding: '10px 0' }}>
                  <Checkbox icon={WhiteCheckIcon} style={{ left: 43 }}>
                    <input
                      type="checkbox"
                      onChange={e => statusChange(e)}
                      name="completable"
                      id="completable"
                      checked={
                        (form &&
                          [COMPLETABLE, COMPLETE].includes(form.status)) ||
                        ''
                      }
                    />
                    <label
                      style={{
                        color:
                          !form ||
                          (![COMPLETABLE, COMPLETE].includes(form.status) &&
                            'rgb(117, 117, 117)'),
                      }}
                      htmlFor="completable"
                    >
                      Task
                    </label>
                  </Checkbox>

                  {form && [COMPLETABLE, COMPLETE].includes(form.status) && (
                    <Checkbox icon={WhiteCheckIcon} style={{ left: 220 }}>
                      <input
                        type="checkbox"
                        onChange={e => statusChange(e)}
                        name="status"
                        id="status"
                        checked={(form && form.status === COMPLETE) || ''}
                      />
                      <label htmlFor="status">Complete</label>
                    </Checkbox>
                  )}
                </FormField>
              </>
            )}

            {/* LOCATION */}
            {!form.type && (
              <FormField style={{ position: 'relative' }}>
                <TextInput
                  icon={LocationIcon}
                  type="text"
                  cols="35"
                  name="location"
                  placeholder="Location"
                  value={(form && form.location) || ''}
                  onChange={e => inputChange(e)}
                />
                {form.location && (
                  <a
                    href={`https://maps.google.com?q=${form.location}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      position: 'absolute',
                      right: 10,
                      top: 6,
                    }}
                  >
                    {' '}
                    📌
                  </a>
                )}
              </FormField>
            )}

            {/* CATEGORY */}
            <FormField style={{ position: 'relative' }}>
              <Select
                icon={CategoryIcon}
                value={form.tags || ''}
                name="tags"
                onChange={e => inputChange(e)}
                style={{
                  cursor: 'pointer',
                  color:
                    (form.tags === '' || !form.tags) && 'rgb(117, 117, 117)',
                  textTransform: 'capitalize',
                }}
              >
                <option value={''}>category</option>
                {CATEGORIES.map(category => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
            </FormField>

            {/* DESCRIPTION */}
            <FormField>
              <TextAreaInput
                icon={DescriptionIcon}
                rows="3"
                minRows={form.type === TYPE_FILE ? 10 : 1}
                maxRows={form.type === TYPE_FILE ? 10 : 5}
                cols="35"
                name="description"
                placeholder="Description"
                value={form.description || ''}
                onChange={e => inputChange(e)}
              />
            </FormField>
            {links && links.length > 0 && (
              <div style={{ marginLeft: 40 }}>
                {(showLinks ? links : [links[0]]).map((link, index) => (
                  <a
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link.includes('@') ? `mailto:${link}` : link}
                    style={{
                      display: 'block',
                      color: styles.Blue,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 310,
                      background: `url(${LinkIcon}) no-repeat left/13px`,
                      textDecoration: 'none',
                      paddingLeft: 20,
                    }}
                  >
                    {link}
                  </a>
                ))}
                {!showLinks && links.length > 1 && (
                  <div
                    style={{
                      cursor: 'pointer',
                      color: styles.Blue,
                      paddingLeft: 20,
                    }}
                    onClick={() => this.setState({ showLinks: true })}
                  >
                    Show more
                  </div>
                )}
              </div>
            )}

            {/* BUTTONS */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 25,
                width: '100%',
              }}
            >
              <div>
                {form && form.id && (
                  <FormButton
                    type="button"
                    onClick={() => deleteAndCloseForm()}
                  >
                    Delete
                  </FormButton>
                )}{' '}
              </div>
              <div>
                <FormButton type="button" onClick={() => closeForm()}>
                  Cancel
                </FormButton>
                <FormButton
                  variant="blue"
                  style={{
                    marginLeft: 10,
                  }}
                  type="submit"
                >
                  Save
                </FormButton>
              </div>
            </div>
            {form.errors && (
              <div style={{ color: 'red', marginTop: 10 }}>{form.errors}</div>
            )}
          </div>
        </div>
      </form>
    );
  }
}
