import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import * as styles from '../../../styles';

export const Day = styled.div`
  width: 23px;
  height: 23px;
  margin: 2px;
  margin-left: 0px;
  padding: 10px;
  border: none;
  line-height: 23px;
  border-radius: 8px;
  text-align: center;
  color: ${props => (props.active ? 'white' : 'grey')};
  background-color: ${props =>
    props.active ? 'rgb(49,114,76)' : styles.BackgroundColor};
  cursor: pointer;
`;

export const Disk = styled.div`
  width: 24px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  padding-top: 4px;
  border-radius: 50%;
  font-size: 11px;
  font-family: ${styles.FontFamilyCooper};
  text-align: center;
  color: white;
`;

export const TextAreaInput = styled(TextareaAutosize)`
  background: url(${props => props.icon}) no-repeat scroll 7px 7px;
  background-size: 24px;
  margin: 2px;
  margin-left: 0px;
  padding: 10px;
  padding-left: 36px;
  border-radius: 8px;
  border: none;
  background-color: ${styles.BackgroundColor};
`;
