import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import * as styles from '../../styles';

export const Container = styled.div`
  flex: 1;
  display: flex;
  color: black;
  font-family: ${styles.FontFamilyCereal};
  overflow-x: hidden;
  background-color: ${styles.BackgroundColor};
  height: 100%;
`;

export const SidePanel = styled.div`
  overflow-y: ${props => !props.isMobile && 'auto'};
  flex: 0 0 ${props => (props.isMobile ? 'calc(100% - 30px)' : '275px')};
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  padding-top: 60px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);

  justify-content: space-between;
  background-color: white;
`;

export const DatesContainer = styled.div`
  margin-top: 10px;
  flex: 1;
  overflow-y: auto;
  background-color: ${styles.BackgroundColor};
`;

export const CenterPanel = styled.div`
  background-color: ${styles.BackgroundColor};
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  max-width: 1000px;
`;

export const CalendarPanel = styled.div`
  padding: 10px;
  background-color: ${styles.BackgroundColor};
`;

export const ItemBlock = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-size: 13px;
  margin: 10px;
  overflow: hidden;
  border-radius: 8px;
  color: #333333;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
`;

export const GrayBlock = styled.div`
  background-color: ${styles.LightGray};
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 13px;
  margin: 10px;
  overflow: hidden;
  border-radius: 8px;
  color: #333333;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
`;

export const TextInput = styled.input`
  width: calc(100% - 48px);
  background: url(${props => props.icon}) no-repeat scroll 7px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }
`;

export const TextAreaInput = styled(TextareaAutosize)`
  width: calc(100% - 48px);
  background: url(${props => props.icon}) no-repeat scroll 7px 7px;
  background-size: 24px;
  padding: 10px;
  padding-left: 36px;
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${styles.BackgroundColor};
  }
  &:focus {
    background-color: ${styles.BackgroundColor};
  }
  resize: none;
`;
