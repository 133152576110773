import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooterLink } from '../components/Card';
import { H2 } from '../components/Type';
import { Item } from '../components/Item';
import { InboxEmptyState, AssigneeEmptyState } from '../components/EmptyState';
import { Button } from '../components/Button';
import { Level } from '../components/Level';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import sortByOrder from './sort';
import { getBotUser } from '../utils';
import { COMPLETE } from '../constants';

import './Inbox.scss';

const Inbox = props => {
  const {
    backlog = [],
    auth,
    showCompleteInBacklog,
    openForm,
    cardMarkComplete,
    userName,
    saveItem,
    moveTo,
  } = props;
  const [columns, setColumns] = useState({});

  const botUserId = getBotUser(auth);

  // Process incoming items into their columns
  useEffect(() => {
    // Initialize the column structure with the default backlog
    const columnsObj = {
      backlog: {
        id: 'backlog',
        title: 'Backlog',
        items: [],
      },
    };

    // Loop through the users and create a column for each one
    const userIds = auth.users.map(user => user.id);
    auth.users
      .filter(user => user.name !== 'bot')
      .forEach(user => {
        columnsObj[user.id] = {
          id: user.id,
          title: user.name,
          items: [],
        };
      });

    // Use the items' order_prev property to put them in order, then put them in the appropriate
    // column
    sortByOrder(backlog)
      .filter(item => showCompleteInBacklog || item.status !== COMPLETE)
      .forEach(item => {
        columnsObj[
          item.assigned_to_id > 0 &&
          item.assigned_to_id !== botUserId &&
          userIds.includes(item.assigned_to_id)
            ? item.assigned_to_id
            : 'backlog'
        ].items.push(item);
      });

    // Save the assembled columns into state
    setColumns(columnsObj);
  }, [backlog, setColumns, auth.users, showCompleteInBacklog, botUserId]);

  return (
    <div className="Inbox">
      <div className="card-container">
        <DragDropContext
          onDragEnd={async result => {
            // Handle a drop. This will move the item in local state, then make the necessary API
            // calls for the backend.

            const { source, destination } = result;

            // Return early if a drop is cancelled
            if (!destination) return;

            // If destination and source are the same, we're reordering wtihin a column
            if (destination.droppableId === source.droppableId) {
              // Return early if it was dropped in the same position it came from
              if (destination.index === source.index) return;

              // Create a copy of the column's array of items and splice the dragged item into its
              // new location
              const column = columns[source.droppableId];
              const items = [...column.items];
              const [draggedItem] = items.splice(source.index, 1);
              items.splice(destination.index, 0, draggedItem);

              // Set the local column state with the modified column
              setColumns({
                ...columns,
                [column.id]: {
                  ...column,
                  items,
                },
              });

              // Figure out what item this one's order_prev should now point to
              const newPrev = destination.index
                ? items[destination.index - 1].id
                : items[destination.index + 1].order_prev;

              // Make the API call to reorder the item
              moveTo(draggedItem.id, newPrev);
            } else {
              // If destination and source aren't the same, we're moving from one column to another

              // Grab the columns and make copies of their item arrays
              const sourceColumn = columns[source.droppableId];
              const destinationColumn = columns[destination.droppableId];
              const sourceItems = [...sourceColumn.items];
              const destinationItems = [...destinationColumn.items];

              // Splice the item from the source to the destination
              const [draggedItem] = sourceItems.splice(source.index, 1);
              destinationItems.splice(destination.index, 0, draggedItem);

              // Set the local column state with both modified columns
              setColumns({
                ...columns,
                [sourceColumn.id]: {
                  ...sourceColumn,
                  items: sourceItems,
                },
                [destinationColumn.id]: {
                  ...destinationColumn,
                  items: destinationItems,
                },
              });

              // Make the API call to update the item on the server with its new assignee
              await saveItem(
                {
                  ...draggedItem,
                  assigned_to_id:
                    parseInt(destinationColumn.id, 10) || undefined,
                },
                // Don't update local state because it'll cause a rerender flash
                // We'll set it later when we call moveTo
                true,
              );

              // Figure out what the moved item's order_prev should now point to
              let newPrev;
              if (destinationItems.length === 1) {
                // If the column that was dragged onto only has this item, leave order_prev alone
                newPrev = draggedItem.order_prev;
              } else if (destination.index === 0) {
                // Otherwise, if the drop position is at the beginning of the column, use the next
                // item's order_prev
                newPrev = destinationItems[destination.index + 1].order_prev;
              } else {
                // Otherwise, just use the previous item
                newPrev = destinationItems[destination.index - 1].id;
              }

              // Make the API call to reorder the item
              moveTo(draggedItem.id, newPrev);
            }
          }}
        >
          {Object.entries(columns)
            .sort((a, b) => {
              if (a[0] === 'backlog') return -1;
              if (b[0] === 'backlog') return 1;
              return a[0] - b[0];
            })
            .map(([columnId, { title, items }]) => (
              <Card key={columnId}>
                <CardBody>
                  <Level>
                    <H2>{title}</H2>
                    <Button
                      variant="clear"
                      onClick={() =>
                        openForm(-1, null, {
                          assigned_to_id:
                            columnId !== 'backlog' ? columnId : null,
                        })
                      }
                    >
                      <box-icon name="plus" />
                    </Button>
                  </Level>
                </CardBody>
                <Droppable droppableId={columnId.toString()}>
                  {droppableProvided => (
                    <div
                      className="droppable-container"
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          index={index}
                          key={item.id}
                          draggableId={item.id.toString()}
                        >
                          {(draggableProvided, draggableSnapshot) => (
                            <Item
                              ref={draggableProvided.innerRef}
                              draggableProps={draggableProvided.draggableProps}
                              dragHandleProps={
                                draggableProvided.dragHandleProps
                              }
                              item={item}
                              auth={auth}
                              openForm={openForm}
                              cardMarkComplete={cardMarkComplete}
                              userName={userName}
                              variant={
                                draggableSnapshot.isDragging && 'dragging'
                              }
                            />
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                      {!items.length &&
                        (columnId === 'backlog' ? (
                          <InboxEmptyState />
                        ) : (
                          <AssigneeEmptyState />
                        ))}
                    </div>
                  )}
                </Droppable>
                <CardFooterLink
                  onClick={() =>
                    openForm(-1, null, {
                      assigned_to_id: columnId !== 'backlog' ? columnId : null,
                    })
                  }
                >
                  <box-icon name="plus" />
                  Add Item
                </CardFooterLink>
              </Card>
            ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default Inbox;
