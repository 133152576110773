import React from 'react';
import { callApi } from '../../utils/callApi';

import './Calendar.scss';

export default class Calendar extends React.Component {
  state = {
    calendars: undefined,
    connectedCalendar: undefined,
  };

  componentDidMount() {
    const { connectionId } = this.props;
    this.getCalendars(connectionId);
  }

  async getCalendars(connectionId) {
    const url = `api/gcal/connections/${connectionId}/calendars`;
    const res = await callApi('GET', url);
    if (res.calendars) {
      this.setState({
        calendars: res.calendars,
        connectedCalendar: res.connected_calendars[0],
      });
    }
  }

  async onChooseCalendar(connectedCalendarId) {
    const { connectionId, setPrimary } = this.props;

    const id = connectedCalendarId === '0' ? null : connectedCalendarId;
    const body = { connected_calendars: [{ id }] };
    const url = `api/gcal/connections/${connectionId}/calendars`;
    await callApi('POST', url, undefined, body);
    this.setState({ connectedCalendar: connectedCalendarId });
    setPrimary(connectionId, id);
  }

  render() {
    const { calendars, connectedCalendar } = this.state;

    return (
      <div className="Calendar">
        <select
          onChange={e => this.onChooseCalendar(e.target.value)}
          value={connectedCalendar || 0}
        >
          <option value="0">Select calendar</option>
          {calendars &&
            calendars.map(cal => (
              <option key={cal.id} value={cal.id}>
                {cal.summary}
              </option>
            ))}
        </select>
      </div>
    );
  }
}
