import React from 'react';
import Modal from 'react-modal';
import { SuggestionsPanel } from '../Components';
import * as styles from '../../../styles';
import EditForm from './EditForm';
import Suggestions from './Suggestions';

export default class Home extends React.Component {
  state = { showSuggestions: true };
  render() {
    const {
      auth,
      form,
      suggestions,
      loadingSuggestions,
      historicalSuggestions,
      open,
      closeForm,
      saveAndCloseForm,
      deleteAndCloseForm,
      inputChange,
      useSuggestion,
      checkboxChange,
      statusChange,
      dateChange,
      timeChange,
      endTimeChange,
    } = this.props;
    const { showSuggestions } = this.state;
    const showSofa =
      (showSuggestions && loadingSuggestions && suggestions.length === 0) ||
      (suggestions.length === 0 && !form.dirty) ||
      (showSuggestions &&
        (!form.title || form.title === '' || form.title.length < 2));
    const editFormHeight = (this.editForm && this.editForm.clientHeight) || 439;
    return (
      <Modal
        isOpen={open}
        onRequestClose={() => closeForm()}
        closeTimeoutMS={100}
        style={{
          content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            top: 'calc(50vh - 287px)',
            bottom: 'unset',
            left: window.innerWidth > 768 ? 'calc(50vw - 433px)' : 0,
            width:
              window.innerWidth > 768 ? (showSuggestions ? 825 : 502) : '100%',
            border: 'none',
            backgroundColor: 'rgb(0,0,0,0)',
            transition: 'width 0.2s',
          },
          overlay: {
            backgroundColor: 'rgb(100,100,100,0.3)',
            zIndex: 2,
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25)',
            borderRadius: 8,
            overflowY: 'auto',
            backgroundColor: 'white',
          }}
          ref={el => (this.editForm = el)}
        >
          <div
            style={{
              boxShadow: '8px  0px 8px -8px rgba(0, 0, 0, 0.25)',
              backgroundColor: 'white',
              padding: 24,
              borderRadius: 8,
              zIndex: 1,
            }}
          >
            <div
              style={{
                fontFamily: styles.FontFamilyCooper,
                fontSize: 22,
                padding: 0,
              }}
            >
              {form.id ? 'Edit Item' : 'New Item'}
            </div>
            <EditForm
              form={form}
              historicalSuggestions={historicalSuggestions}
              suggestions={suggestions}
              users={auth && auth.users}
              saveAndCloseForm={e => saveAndCloseForm(e)}
              deleteAndCloseForm={() => deleteAndCloseForm()}
              inputChange={e => inputChange(e)}
              useSuggestion={e => useSuggestion(e)}
              checkboxChange={e => checkboxChange(e)}
              statusChange={e => statusChange(e)}
              dateChange={date => dateChange(date)}
              timeChange={time => timeChange(time)}
              endTimeChange={time => endTimeChange(time)}
              closeForm={() => closeForm()}
              maxHeight={editFormHeight}
            />
          </div>
          <SuggestionsPanel
            style={{
              marginLeft: !showSuggestions ? -330 : -7,
              backgroundImage:
                showSofa && "url('/Suggestions-Default-Illustration.png')",
              backgroundSize: showSofa && 300,
              backgroundPosition: showSofa && 'right bottom',
              backgroundRepeat: showSofa && 'no-repeat',
            }}
          >
            <Suggestions
              maxHeight={editFormHeight}
              showSuggestions={showSuggestions}
              suggestions={suggestions}
              loadingSuggestions={loadingSuggestions}
              query={form.title}
              dirty={form.dirty}
              useSuggestion={e => useSuggestion(e)}
              show={() => this.setState({ showSuggestions: true })}
              hide={() => this.setState({ showSuggestions: false })}
            />
          </SuggestionsPanel>
        </div>
      </Modal>
    );
  }
}
