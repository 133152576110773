const INPUT_SET = 'INPUT_SET';
const INPUT_CLEAR = 'INPUT_CLEAR';
const initialState = { text: '' };

export function set(value) {
  return { type: INPUT_SET, value };
}

export function clear() {
  return { type: INPUT_CLEAR };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === INPUT_SET) {
    result = { ...state, ...(action.value || initialState) };
  } else if (action.type === INPUT_CLEAR) {
    result = initialState;
  }
  return result;
}
