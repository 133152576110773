import * as analytics from './analytics';
import { getAccessToken } from '../redux/auth';

export async function callApi(method, endPoint, params, body) {
  try {
    const url = `/${endPoint}${params ? '?' : ''}${params ? params : ''}`;
    const accessToken = getAccessToken();
    const headers = { 'Content-Type': 'application/json' };
    const options = {
      method,
      body: JSON.stringify(body),
      headers,
    };
    if (accessToken) {
      options.credentials = 'include';
      options.headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    const res = await fetch(url, options);
    const responseBody = await res.text();
    try {
      analytics.track('API Call', {
        method,
        endPoint,
      });
      return JSON.parse(responseBody);
    } catch (error) {
      return res.status;
    }
  } catch (error) {
    analytics.track('API Call Error', {
      method,
      endPoint,
      error,
    });
    console.error(error);
    throw error;
  }
}
