const CHANNEL_SET = 'CHANNEL_SET';
const initialState = { id: 0, name: 'general' };

export function set(channel) {
  return { type: CHANNEL_SET, channel };
}
export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === CHANNEL_SET) {
    result = action.channel;
  }
  return result;
}
