import React from 'react';
import { connect } from 'react-redux';
import { callApi } from '../../utils/callApi';
import * as itemAction from '../../redux/items';
import * as channelsAction from '../../redux/channels';
import { storeLogin } from '../../redux/auth';
import { LogoPanel, Background, Panel, TextInput } from './Components';
import { Button } from '../Button/Button';
import Logo from '../../assets/icons/LogoInverse192.png';

class Join extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleSignupClick(e) {
    e.preventDefault();
    const {
      match: {
        params: { familyHash, groupHash },
      },
    } = this.props;

    const { name, email, password, confirm, family_name } = this.state;

    if (!name || !email || !password || !confirm || !family_name) {
      this.setState({ errors: ['Please complete all fields'] });
      return;
    }

    if (password !== confirm) {
      this.setState({ errors: ['Password and confirmation do not match'] });
      return;
    }
    const body = {
      name,
      email,
      password,
      family_hash: familyHash,
      group_hash: groupHash,
      family_name,
    };
    this.setState({ loading: true });
    const result = await callApi('POST', 'api/join', undefined, body);
    if (result && result.error) {
      alert(result.error);
      this.setState({ loading: false });
      return;
    }
    const auth = await callApi('POST', 'login', undefined, body);
    await this.props.handleStoreLogin(auth);
    this.props.history.push(`/pods`);
  }

  async handleLoginClick(e) {
    e.preventDefault();
    const {
      match: {
        params: { groupHash },
      },
    } = this.props;

    const { email, password } = this.state;
    if (!email || !email.length) {
      return;
    }
    const body = { email, password };
    this.setState({ loading: true });
    const auth = await callApi('POST', 'login', undefined, body);
    if (auth.access_token) {
      await this.props.handleStoreLogin(auth);
      const groupBody = { group_hash: groupHash };
      const result = await callApi('POST', 'api/join', undefined, groupBody);
      if (result && result.error) {
        alert(result.error);
        this.setState({ loading: false });
        return;
      }
      const auth2 = await callApi('POST', 'login', undefined, body);
      await this.props.handleStoreLogin(auth2);
      this.setState({ loading: false });
      this.props.history.push(`/pods`);
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      match: {
        params: { familyHash },
      },
    } = this.props;

    const { loading, errors, doLogin } = this.state;
    return (
      <Background>
        <LogoPanel>
          <img src={Logo} alt="logo" width="96px" height="96px" />
        </LogoPanel>

        <Panel>
          <h1 style={{ textAlign: 'center' }}>Join Modern Village</h1>
          <form>
            {!doLogin && (
              <div>
                <TextInput
                  autoFocus
                  disabled={loading}
                  onChange={e => this.onInput(e)}
                  type="name"
                  name="name"
                  placeholder="Your name (e.g. Bill)"
                ></TextInput>
              </div>
            )}
            {!doLogin && !familyHash && familyHash !== 'a' && (
              <div>
                <TextInput
                  disabled={loading}
                  onChange={e => this.onInput(e)}
                  type="name"
                  name="family_name"
                  placeholder="Family name (e.g. The Smiths)"
                ></TextInput>
              </div>
            )}

            <div>
              <TextInput
                disabled={loading}
                onChange={e => this.onInput(e)}
                type="email"
                name="email"
                placeholder="Your email address"
              ></TextInput>
            </div>

            <div>
              <TextInput
                disabled={loading}
                type="password"
                name="password"
                placeholder="Your password"
                onChange={e => this.onInput(e)}
              ></TextInput>
            </div>
            {!doLogin && (
              <React.Fragment>
                <div>
                  <TextInput
                    disabled={loading}
                    type="password"
                    name="confirm"
                    placeholder="Confirm password"
                    onChange={e => this.onInput(e)}
                  ></TextInput>
                </div>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <Button
                    disabled={loading}
                    onClick={e => this.handleSignupClick(e)}
                  >
                    {loading ? 'Loading...' : 'Signup'}
                  </Button>
                  {errors && (
                    <div>
                      <br />
                      <span style={{ color: 'red' }}>Error: </span>
                      {errors}
                    </div>
                  )}
                </div>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <span style={{ marginRight: 20 }}>
                    I already have an account
                  </span>
                  <br />
                  <Button
                    disabled={loading}
                    onClick={() => this.setState({ doLogin: true })}
                  >
                    {loading ? 'Loading...' : 'Login'}
                  </Button>
                </div>
              </React.Fragment>
            )}
            {doLogin && (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <Button
                  disabled={loading}
                  onClick={e => this.handleLoginClick(e)}
                >
                  {loading ? 'Loading...' : 'Login'}
                </Button>
                {errors}
              </div>
            )}
          </form>
        </Panel>
      </Background>
    );
  }
}

export default connect(undefined, {
  handleStoreLogin: storeLogin,
  storeChannels: channelsAction.set,
  dispatchSetItems: itemAction.set,
})(Join);
