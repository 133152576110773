import React from 'react';
import './TextInput.scss';

const TextInput = props => {
  const {
    name,
    placeHolder,
    value,
    cols,
    icon,
    onChange,
    onFocus,
    onBlur,
    autoFocus,
    type,
  } = props;
  return (
    <input
      className="TextInput"
      icon={icon}
      autoFocus={autoFocus}
      type={type || 'text'}
      cols={cols || 40}
      name={name}
      placeholder={placeHolder}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default TextInput;
