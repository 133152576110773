import React from 'react';
import { connect } from 'react-redux';
import idx from 'idx';
import moment from 'moment';
import { CalloutName, CalloutTime } from './Components';
import { White } from '../../styles';

class Callout extends React.Component {
  render() {
    const user = idx(this.props, _ => _.item.user);
    const time = idx(this.props, _ => _.item.created_at);
    const formattedTime = moment(time).format('h:mma');
    return (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            marginLeft: 22,
            width: 17,
            height: 21,
            borderRadius: '50%',
            backgroundColor: user.color,
            color: White,
            paddingLeft: 6,
            paddingTop: 1,
          }}
        >
          {user.name && user.name[0]}
        </div>
        <CalloutName>{user.name}</CalloutName>
        <CalloutTime>{formattedTime}</CalloutTime>
      </div>
    );
  }
}

export default connect(undefined, {})(Callout);
