import React from 'react';
import { withRouter } from 'react-router-dom';
import idx from 'idx';
import { H4 } from './components/Type';
import SubNavLink from './SubNavLink';
import WeekSelect from './Week/WeekSelect';

import './SubNav.scss';

const SubNav = props => {
  const {
    location,
    weekStartDate,
    inboxUnread,
    filesUnread,
    weekToday,
    weekBack,
    weekForward,
    showCompleteInBacklog,
    toggleShowCompleteInBacklog,
  } = props;
  const pathParts = idx(location, _ => _.pathname.split('/'));
  const active = pathParts && pathParts[2];
  return (
    <div className="SubNav">
      <div className="menu">
        <H4>Pages</H4>
        <SubNavLink
          product="home"
          feature="dashboard"
          active={!active || active === 'dashboard'}
        >
          <box-icon type="solid" name="dashboard" />
          &nbsp;Dash
        </SubNavLink>
        <SubNavLink product="home" feature="week" active={active === 'week'}>
          <box-icon name="calendar-alt" />
          &nbsp;Week
        </SubNavLink>
        <SubNavLink
          product="home"
          feature="inbox"
          active={active === 'inbox'}
          badge={inboxUnread}
        >
          <box-icon name="paper-plane" />
          &nbsp;Inbox
        </SubNavLink>
        <SubNavLink
          product="home"
          feature="files"
          active={active === 'files'}
          badge={filesUnread}
        >
          <box-icon name="cabinet" />
          &nbsp;Files
        </SubNavLink>

        <H4>Tools</H4>
        <SubNavLink
          active={active === 'planner'}
          onClick={() => props.openPlanner2()}
        >
          <box-icon name="list-ul" />
          &nbsp;Planner
        </SubNavLink>
      </div>

      {active === 'week' && (
        <WeekSelect
          weekStartDate={weekStartDate}
          weekToday={weekToday}
          weekBack={weekBack}
          weekForward={weekForward}
        />
      )}
      {active === 'inbox' && (
        <div className="include-complete">
          <label>
            <input
              type="checkbox"
              onChange={() => toggleShowCompleteInBacklog()}
              checked={showCompleteInBacklog || ''}
            />{' '}
            Include complete
          </label>
        </div>
      )}
    </div>
  );
};

export default withRouter(SubNav);
