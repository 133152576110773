import React from 'react';
import { TextInput, TextAreaInput } from './Components';
import { Button } from '../Home/components/Button';
import JournalModal from './JournalModal';
import * as styles from '../../styles';

import TitleIcon from '../Icons/Title.png';
import DescriptionIcon from '../Icons/Description.png';

const EditJournal = props => {
  const { form, inputChange, closeForm, saveAndCloseForm } = props;
  return (
    <JournalModal
      open={true}
      closeForm={() => closeForm()}
      title={form.id > 0 ? 'Edit Journal Entry' : 'New Journal Entry'}
    >
      <form autoComplete="off" onSubmit={e => saveAndCloseForm(e)}>
        <TextInput
          icon={TitleIcon}
          autoFocus
          name="title"
          placeholder="Title"
          value={(form && form.title) || ''}
          onChange={e => inputChange(e)}
        />
        <TextAreaInput
          icon={DescriptionIcon}
          rows="3"
          minRows={10}
          maxRows={10}
          cols="35"
          name="description"
          placeholder="Description"
          value={form.description || ''}
          onChange={e => inputChange(e)}
        />
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="button"
            variant="small"
            onClick={() => closeForm()}
            style={{
              color: styles.Black,
              backgroundColor: styles.BackgroundColor,
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            disabled={!form.title && !form.description}
          >
            Save
          </Button>
        </div>
      </form>
    </JournalModal>
  );
};

export default EditJournal;
