import React from 'react';
import { H1, H3 } from '../Home/components/Type';
import { Button } from '../Home/components/Button';
import FakeAddButton from '../FakeAddButton/FakeAddButton';

import './Circles.scss';

const Circles = () => (
  <div className="Circles">
    <div className="content">
      <H3>Coming Soon</H3>
      <H1>A Place to Connect</H1>
      <p
        style={{
          color: '#666',
          margin: '10px auto 20px auto',
          maxWidth: '375px',
        }}
      >
        Join forces with a couple other families you know and create a Circle to
        safely share the load with&mdash; whether it&rsquo;s school, playdates,
        or childcare.
      </p>
      <Button>
        <a
          href="https://themodernvillage.typeform.com/to/dDqtT9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request Early Access
        </a>
      </Button>
    </div>
    <FakeAddButton />
  </div>
);

export default Circles;
