import styled from 'styled-components';
import { FontFamilyCooper, Blue } from '../../styles';

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${Blue};
  margin: 0px;
  @media (max-width: 768px) {
    padding-bottom: 300px;
  }
`;

export const SignupPanel = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;

export const LogoPanel = styled.div`
  @media (min-width: 769px) {
    position: fixed;
    top: 30px;
    left: 30px;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Panel = styled.div`
  margin: auto;
  position: relative;
  height: 60vh;
  border-width: 0;
  padding: 20px;

  color: White;
  align-content: center;

  border-radius: 12pt;
  @media (min-width: 769px) {
    width: 40vw;
    top: 20%;
  }
`;

export const TextInput = styled.input`
  font-size: 16px;
  font-family: ${FontFamilyCooper};
  padding: 5px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border: 0px solid black;
  border-radius: 6pt;
  line-height: 24px;
  box-shadow: 2px 3px 5px 0 rgba(155, 155, 155, 0.62);
  -webkit-box-shadow: 2px 3px 5px 0 rgba(155, 155, 155, 0.62);
  -moz-box-shadow: 2px 3px 5px 0 rgba(155, 155, 155, 0.62);
  width: calc(100% - 27px);
`;
