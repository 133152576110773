import React from 'react';
import * as analytics from '../../utils/analytics';
import { callApi } from '../../utils/callApi';
import PicksView from '../Picks/PicksView';
import Loading from '../../assets/icons/Loading.gif';
import { Container } from './Components';
import * as styles from '../../styles';
import PickBannerBackground from './pick-list-bg.png';

export default class Picks extends React.Component {
  state = {
    picks: [],
    loading: false,
  };

  async componentDidMount() {
    analytics.page('Picks');
    await this.getPicks();
  }

  async getPicks() {
    this.setState({ loading: true });
    const {
      match: {
        params: { hash },
      },
    } = this.props;
    const picks = await callApi('GET', `api/picks/${hash}`);
    if (Array.isArray(picks) && picks.length > 0) {
      this.setState({
        picks: picks,
        loading: false,
      });
    } else {
      this.props.history.push(`/`);
    }
  }

  render() {
    const { picks, loading } = this.state;
    if (loading) {
      return (
        <div style={{ position: 'absolute', top: '40vh', left: '48vw' }}>
          <img src={Loading} alt="Loading" />
        </div>
      );
    }

    // Show picks with images first
    const sortedPicks = picks.sort((a, b) => {
      if (a.data.image_url && !b.data.image_url) {
        return -1;
      }
      return 0;
    });
    const name = picks && picks[0] && picks[0].display_name;
    const imageUrl =
      (picks && picks[0] && picks[0].image_url) ||
      'https://mv-prod-content.s3.us-west-2.amazonaws.com/nanny-phone-noBG.png';
    return (
      <Container>
        <div
          style={{
            height: 190,
            textTransform: 'capitalize',
            textAlign: 'center',
            fontSize: styles.FontSizeExtraLarge,
            padding: 20,
            backgroundImage: `url(${PickBannerBackground})`,
            backgroundSize: 'cover',
          }}
        >
          <div>
            <img
              width="150"
              src={imageUrl}
              style={{ borderRadius: 75 }}
              alt="pick"
            />
          </div>
          <div>{name}&apos;s Parent Picks</div>
        </div>
        <div
          style={{
            borderRadius: 20,
            backgroundColor: 'white',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <PicksView items={sortedPicks} />
        </div>
        <div></div>
      </Container>
    );
  }
}
