import styled from 'styled-components';
import { FontFamilyCooper, FontSizeSmall, Gray } from '../../styles';

export const Container = styled.div`
  background-color: #e8f0fe;
  color: black;
  font-family: ${FontFamilyCooper};
`;

export const PickItemPanel = styled.div`
  margin: 10px;
  margin-bottom: 15px;
  background-color: #f1f8fb;
  color: black;
  padding: 20px;
  border-radius: 5px;
  width: calc(100vw - 60px);
`;

export const PickElement = styled.div`
  display: flex;
`;

export const CalloutName = styled.div`
  margin-left: 18px;
  color: black;
  font-family: ${FontFamilyCooper};
`;

export const CalloutTime = styled.div`
  margin-left: 5px;
  color: ${Gray};
  font-size: ${FontSizeSmall};
  padding-top: 4px;
`;
