import React from 'react';
import classNames from 'classnames';
import './Level.scss';

export const Level = props => (
  <div
    onClick={props.onClick}
    className={classNames('Level', props.variant, {
      clickable: !!props.onClick,
    })}
  >
    {props.children}
  </div>
);
