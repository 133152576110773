export const BackgroundColor = '#f2f6fb';
export const BorderColor = 'rgb(218, 222, 229)';
export const AccentColor = '#6766FE'; // Slate blue
export const White = 'white';
export const Black = 'black';
export const Gray = 'gray';
export const LightGray = 'lightgray';
export const Blue = '#3757a7';
export const HighlightBlue = '#3d58a2';

export const FontSizeSmallest = '10px';
export const FontSizeSmall = '12px';
export const FontSizeMedium = '16px';
export const FontSizeLarge = '20px';
export const FontSizeExtraLarge = '26px';

export const FontFamilyCooper = 'Cooper-Light-BT';
export const FontFamilyCereal = 'AirbnbCerealBook';
