import React from 'react';
import moment from 'moment';
import { BLANK_TIME } from './align-days';
import { DayTitle } from '../Components';
import * as styles from '../../../styles';
import { DATE_FORMAT } from '../constants';
import { Item, BlankItem } from '../components/Item';

import './Dashboard.scss';

class DashboardGrid extends React.Component {
  state = { lastStartDate: undefined };

  scrollDashboardToDay() {
    if (this.todayRef && this.todayRef.el) {
      // mobile
      document
        .getElementsByTagName('body')[0]
        .scrollTo(this.todayRef.el.offsetWidth * this.todayRef.i, 0);
      // desktop
      document.documentElement.scrollTo(
        this.todayRef.el.offsetWidth * this.todayRef.i,
        0,
      );
    }
  }

  componentDidUpdate() {
    const { scrollToToday, setScrollToToday } = this.props;
    if (scrollToToday) {
      this.scrollDashboardToDay();
      setScrollToToday(false);
    }
  }

  componentDidMount() {
    document.documentElement.classList.add('body-scroll');
    this.scrollDashboardToDay();
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('body-scroll');
  }

  render() {
    const {
      weekStartDate,
      open,
      plannerOpen,
      planner2Open,
      auth,
      openForm,
      cardMarkComplete,
      userName,
    } = this.props;

    return (
      <div className="Dashboard">
        <div className="calendar-column-container">
          {[0, 1, 2, 3, 4, 5, 6].map(i => {
            const day = moment(weekStartDate).add(i, 'days').format('dddd');

            const date = moment(weekStartDate).add(i, 'days');
            const formattedDate = date.format('MMM D');
            const today =
              date.format(DATE_FORMAT) === moment().format(DATE_FORMAT);
            const past = moment().startOf('day').isAfter(date);
            const { itemsByDay } = this.props;
            const items = itemsByDay && itemsByDay[i];
            return (
              <div
                className="calendar-column"
                key={i}
                ref={el => {
                  if (today) {
                    this.todayRef = { el, i };
                  }
                }}
              >
                <div
                  className="calendar-column-header"
                  style={{
                    zIndex: !open && !plannerOpen && !planner2Open && 1,
                  }}
                >
                  <DayTitle
                    style={
                      today
                        ? {
                            borderColor: styles.HighlightBlue,
                            color: styles.HighlightBlue,
                            borderWidth: 2,
                            paddingBottom: 9,
                          }
                        : {}
                    }
                  >
                    <div style={{ opacity: past && 0.5 }}>
                      {day},{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {formattedDate}
                      </span>
                    </div>
                  </DayTitle>
                </div>
                <div className="calendar-column-items">
                  {items &&
                    items.map((item, index) => {
                      if (item.time === BLANK_TIME) {
                        return <BlankItem key={index} />;
                      } else {
                        return (
                          <Item
                            key={item.id}
                            item={item}
                            auth={auth}
                            hideUnreadBadge={true}
                            openForm={openForm}
                            cardMarkComplete={cardMarkComplete}
                            userName={userName}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DashboardGrid;
