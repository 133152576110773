const COUNT_SET = 'COUNT_SET';
const COUNT_RESET = 'COUNT_RESET';
const initialState = 0;

export function set(count) {
  return { type: COUNT_SET, count };
}

export function reset() {
  return { type: COUNT_RESET };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === COUNT_SET) {
    result = action.count;
  } else if (action.type === COUNT_RESET) {
    result = 0;
  }
  return result;
}
