const MODAL_SET = 'MODAL_SET';
const initialState = false;

export function open(value) {
  return { type: MODAL_SET, value };
}

export function closeAll() {
  return { type: MODAL_SET, value: initialState };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === MODAL_SET) {
    result = action.value;
  }
  return result;
}
