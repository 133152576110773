import * as analytics from '../utils/analytics';
import { mvSocket } from '../components/Home/Home';

const AUTH_STORE_LOGIN = 'AUTH_STORE_LOGIN';
const AUTH_RESET = 'AUTH_RESET';

const initialState = {
  loggedIn: false,
  email: undefined,
  access_token: undefined,
  refresh_token: undefined,
};

export function storeLogin(authData) {
  if (authData.access_token) {
    localStorage.setItem('access_token', authData.access_token);
    localStorage.setItem('refresh_token', authData.refresh_token);
  }
  const data = {
    ...authData,
    loggedIn: true,
    access_token: undefined,
    refresh_token: undefined,
  };
  return { type: AUTH_STORE_LOGIN, data };
}

export function clearPasswordRequired() {
  const data = {
    password_required: false,
  };
  return { type: AUTH_STORE_LOGIN, data };
}

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

export function reset() {
  // Remove all data
  localStorage.clear();
  analytics.track('logout');
  mvSocket && mvSocket.disconnect();

  return { type: AUTH_RESET };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === AUTH_STORE_LOGIN) {
    result = { ...state, ...action.data };
  } else if (action.type === AUTH_RESET) {
    result = initialState;
  }
  return result;
}
