function sortByOrder(items) {
  const result = [];

  const hash = items.reduce(
    (acc, item) => ({
      ...acc,
      [item.order_prev]: item,
    }),
    {},
  );
  function add(item) {
    item && result.push(item);
    if (item && item.id) {
      add(hash[item.id]);
    }
  }
  const top = items.find(item => !item.date && !item.order_prev);
  add(top);
  return result;
}

export default sortByOrder;
