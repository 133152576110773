function isProduction() {
  return window.location.origin.includes('app.modernvillage.com');
}

export function identify(id, optional = {}) {
  isProduction() && window.analytics && window.analytics.identify(id, optional);
}

export function track(eventName, optional = {}) {
  isProduction() &&
    window.analytics &&
    window.analytics.track(eventName, optional);
}

export function page(pageName, optional = {}) {
  isProduction() &&
    window.analytics &&
    window.analytics.page(pageName, optional);
}
