import React from 'react';
import './Checkbox.scss';

export const Checkbox = ({ id, onChange, checked }) => (
  <div className="Checkbox">
    <input
      type="checkbox"
      id={`checkbox_${id}`}
      onChange={onChange}
      onClick={evt => evt.stopPropagation()}
      name="status"
      checked={checked}
    />
    <label onClick={evt => evt.stopPropagation()} htmlFor={`checkbox_${id}`} />
  </div>
);
