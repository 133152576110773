import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './SubNavLink.scss';

const SubNavLink = props => {
  const { product, feature, active, badge, children, onClick } = props;

  if (onClick) {
    return (
      <div className="SubNavLink" onClick={onClick}>
        {children}
      </div>
    );
  } else {
    return (
      <Link
        className={classNames('SubNavLink', { active })}
        to={`/${product}/${feature}`}
      >
        {children}
        {!!badge && <div className="badge">{badge}</div>}
      </Link>
    );
  }
};

export default SubNavLink;
