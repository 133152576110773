import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import './WeekSelect.scss';

class WeekSelect extends React.Component {
  render() {
    const {
      weekStartDate,
      weekToday,
      weekBack,
      weekForward,
      hideTodayButton,
    } = this.props;
    const classNames = classnames('WeekSelect', {
      'WeekSelect__with-today': !hideTodayButton,
    });
    return (
      <div className={classNames}>
        <div className="date-select">
          <div>
            <button className="button" onClick={() => weekBack()}>
              &lt;
            </button>
          </div>
          <div className="date-field">
            {moment(weekStartDate).format('MMM D, YYYY')}
          </div>
          <button
            className="button button__right"
            onClick={() => weekForward()}
          >
            &gt;
          </button>
        </div>
        {!hideTodayButton && (
          <button
            className="button button__today"
            onClick={async () => {
              await weekToday();
            }}
          >
            Today
          </button>
        )}
      </div>
    );
  }
}

export default WeekSelect;
