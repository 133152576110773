import React from 'react';
import { Card, CardBody, CardFooterLink } from '../components/Card';
import { H2 } from '../components/Type';
import { Item } from '../components/Item';
import { InboxEmptyState } from '../components/EmptyState';
import { Button } from '../components/Button';
import { Level } from '../components/Level';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import sortByOrder from '../Inbox/sort';
import { TYPE_FILE } from '../constants';

import './Files.scss';

const Files = props => {
  const {
    files = [],
    auth,
    openForm,
    cardMarkComplete,
    userName,
    moveTo,
  } = props;
  const items = sortByOrder(files);
  return (
    <div className="Files">
      <div className="card-container">
        <DragDropContext
          onDragEnd={async result => {
            // Handle a drop. This will move the item in local state, then make the necessary API
            // calls for the backend.

            const { source, destination } = result;

            // Return early if a drop is cancelled
            if (!destination) return;

            // If destination and source are the same, we're reordering wtihin a column
            // Return early if it was dropped in the same position it came from
            if (destination.index === source.index) return;

            // Create a copy of the column's array of items and splice the dragged item into its
            // new location
            const [draggedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, draggedItem);

            // Figure out what item this one's order_prev should now point to
            const newPrev = destination.index
              ? items[destination.index - 1].id
              : items[destination.index + 1].order_prev;

            // Make the API call to reorder the item
            await moveTo(draggedItem.id, newPrev);
          }}
        >
          <Card>
            <CardBody>
              <Level>
                <H2>Files</H2>
                <Button
                  variant="clear"
                  onClick={() => openForm(-1, null, { type: TYPE_FILE })}
                >
                  <box-icon name="plus" />
                </Button>
              </Level>
            </CardBody>
            <Droppable droppableId={'0'}>
              {droppableProvided => (
                <div
                  className="droppable-container"
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {items.map((item, index) => (
                    <Draggable
                      index={index}
                      key={item.id}
                      draggableId={item.id.toString()}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <Item
                          ref={draggableProvided.innerRef}
                          draggableProps={draggableProvided.draggableProps}
                          dragHandleProps={draggableProvided.dragHandleProps}
                          item={item}
                          auth={auth}
                          openForm={openForm}
                          cardMarkComplete={cardMarkComplete}
                          userName={userName}
                          variant={draggableSnapshot.isDragging && 'dragging'}
                        />
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                  {!items.length && <InboxEmptyState />}
                </div>
              )}
            </Droppable>
            <CardFooterLink
              onClick={() => openForm(-1, null, { type: TYPE_FILE })}
            >
              <box-icon name="plus" />
              Add Item
            </CardFooterLink>
          </Card>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Files;
