import moment from 'moment';

export const FRIENDLY_DATE_FORMAT = 'ddd, MMM D';
export const FRIENDLY_TIME_FORMAT = 'h:mm';
export const FRIENDLY_HOUR_FORMAT = 'h';
export const AM_PM = 'a';

export function formatDate(date) {
  return date && moment(date).format(FRIENDLY_DATE_FORMAT);
}

export function formatTime(date, time, endTime) {
  const timeMoment = date && time && moment(date + ' ' + time);
  const endTimeMoment = date && endTime && moment(date + ' ' + endTime);
  const sameAmPm =
    timeMoment &&
    endTimeMoment &&
    timeMoment.format('a') === endTimeMoment.format('a');
  return timeMoment && timeMoment.minutes() > 0
    ? timeMoment.format(
        FRIENDLY_TIME_FORMAT + (endTime && sameAmPm ? '' : AM_PM),
      )
    : timeMoment &&
        moment(date + ' ' + time).format(
          FRIENDLY_HOUR_FORMAT + (endTime && sameAmPm ? '' : AM_PM),
        );
}
