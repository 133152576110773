import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './NavLink.scss';

const NavLink = props => {
  const { product, active, children } = props;
  return (
    <Link
      className={classNames('NavLink', `product-${product}`, { active })}
      to={`/${product}`}
    >
      {children}
    </Link>
  );
};

export default NavLink;
