const TYPE_SET = 'TYPE_SET';
const initialState = 'message';

export function set(value) {
  return { type: TYPE_SET, value };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === TYPE_SET) {
    result = action.value;
  }
  return result;
}
