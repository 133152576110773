import React from 'react';
import { H1, H3 } from '../Home/components/Type';
import { Button } from '../Home/components/Button';
import FakeAddButton from '../FakeAddButton/FakeAddButton';

import './JournalStub.scss';

const JournalStub = () => (
  <div className="JournalStub">
    <div className="content">
      <H3>Coming Soon</H3>
      <H1>A Place to Reflect</H1>
      <p
        style={{
          color: '#666',
          margin: '10px auto 20px auto',
          maxWidth: '325px',
        }}
      >
        Answer daily email or text prompts to capture memories big and small on
        the go.
      </p>
      <Button>
        <a
          href="https://themodernvillage.typeform.com/to/dDqtT9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request Early Access
        </a>
      </Button>
    </div>
    <FakeAddButton />
  </div>
);
export default JournalStub;
