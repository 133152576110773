const SHOW_MENU = 'SHOW_MENU';
const HIDE_MENU = 'HIDE_MENU';
const initialState = false;

export function show() {
  return { type: SHOW_MENU };
}

export function hide() {
  return { type: HIDE_MENU };
}

export default function reducer(state = initialState, action) {
  let result = state;
  if (action.type === SHOW_MENU) {
    result = true;
  } else if (action.type === HIDE_MENU) {
    result = false;
  }
  return result;
}
