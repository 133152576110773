import styled from 'styled-components';
import { AccentColor, White, FontFamilyCooper } from '../../styles';

export const Button = styled.button`
  font-size: 16px;
  font-family: ${FontFamilyCooper};
  padding: 5px;
  border: 1px solid ${AccentColor};
  border-radius: 12pt;
  line-height: 24px;
  width: 200px;
  background-color: ${AccentColor};
  color: ${White};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default Button;
