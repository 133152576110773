import moment from 'moment';
import * as utils from '../utils';

export function itemsToPlannerItems(lastWeekItems) {
  // Create an item for each group
  // (ignore if no planner group)
  const groups = [
    ...new Set(
      utils.sortItems(lastWeekItems).map(item => item.data.plannerGroup),
    ),
  ].filter(e => e);

  const newPlannerItems = groups.map(group => ({
    id: group,
  }));

  // Set the days
  newPlannerItems.forEach(plannerItem => {
    const items = lastWeekItems.filter(
      item => item.data.plannerGroup === plannerItem.id,
    );
    plannerItem.title = items[0].title;
    plannerItem.time = items[0].time;
    plannerItem.endTime = items[0].end_time;
    plannerItem.daily = items[0].data.plannerType === 'daily';
    plannerItem.days = [{}, {}, {}, {}, {}, {}, {}];
    items.forEach(item => {
      plannerItem.days[dayIndex(item)].active = true;
    });
    if (plannerItem.daily) {
      items.forEach(item => {
        plannerItem.days[dayIndex(item)].assigned_to_id = item.assigned_to_id;
        plannerItem.days[dayIndex(item)].description = item.description;
      });
    } else {
      plannerItem.description = items[0].description;
      plannerItem.assigned_to_id = items[0].assigned_to_id;
    }
  });

  return newPlannerItems;
}

function dayIndex(item) {
  const dayMoment = item.date && moment(item.date);
  return dayMoment
    ? dayMoment.day() === 0
      ? 6
      : dayMoment.day() - 1
    : undefined;
}
