import React from 'react';
import { Button } from '../Home/components/Button';
import { AddNewButton } from '../Home/Components';
import BigPlusIcon from '../Icons/BigPlus.png';

const isMobile = () => {
  return window.innerWidth <= 768;
};

const FakeAddButton = () => (
  <>
    {isMobile() && (
      <AddNewButton
        style={{
          bottom: 10,
          zIndex: 100,
        }}
        icon={BigPlusIcon}
      />
    )}

    {!isMobile() && (
      <Button
        variant="small"
        style={{ position: 'absolute', top: 20, right: 20 }}
      >
        ＋ New
      </Button>
    )}
  </>
);

export default FakeAddButton;
