import { combineReducers } from 'redux';
import auth from './auth';
import items from './items';
import editItem from './edit-item';
import input from './input';
import scroll from './scroll';
import menu from './menu';
import channel from './channel';
import channels from './channels';
import loading from './loading';
import tab from './tab';
import type from './type';
import modal from './modal';
import count from './count';

export default combineReducers({
  auth,
  items,
  editItem,
  input,
  scroll,
  menu,
  channel,
  channels,
  loading,
  tab,
  type,
  modal,
  count,
});
