import Modal from 'react-modal';
import moment from 'moment';
import uuid from 'uuid/v4';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { sortItemsByTime } from '../utils';
import { DATE_FORMAT } from '../constants';
import { callApi } from '../../../utils/callApi';
import { itemsToPlannerItems } from './items-to-planner-items';
import Planner2Item from './Planner2Item.js';
import { FormButton } from '../Components';
import WeekSelect from '../Week/WeekSelect';

import './Planner2.scss';

import XIcon from '../../Icons/BigX.png';

class Planner2 extends React.Component {
  state = {
    lastWeek: undefined,
    loading: false,
    plannerData: [{ days: [{}, {}, {}, {}, {}, {}, {}] }],
    lastWeekItems: undefined,
  };

  componentDidMount() {
    this.getLastWeek();
  }

  componentDidUpdate() {
    const { lastWeek } = this.state;
    const { weekStartDate } = this.props;
    if (lastWeek !== weekStartDate) {
      this.getLastWeek();
      this.setState({ lastWeek: weekStartDate });
    }
  }

  addPlannerItem() {
    const { plannerData } = this.state;
    this.setState({
      plannerData: [...plannerData, { days: [{}, {}, {}, {}, {}, {}, {}] }],
    });
  }

  onPlannerItemDataChange(itemIndex, newData) {
    const { plannerData } = this.state;
    const updated = plannerData.map((item, index) =>
      index === itemIndex ? newData : item,
    );
    this.setState({ plannerData: updated });
  }

  onPlannerItemDelete(itemIndex) {
    const { plannerData } = this.state;
    const updated = plannerData.filter((_, index) => index !== itemIndex);
    this.setState({ plannerData: updated });
  }

  getLastWeek() {
    // call the api with last weeks data range
    const { weekStartDate } = this.props;
    const start = moment(weekStartDate).add(-7, 'days').format(DATE_FORMAT);
    const end = moment(start).add(6, 'days').format(DATE_FORMAT);
    const url = `start_date=${start}&end_date=${end}}&source=planner`;
    callApi('GET', 'api/items', url).then(items => {
      const withDate = items && items.filter(item => item.date);
      this.setState({ lastWeekItems: withDate });
    });
  }

  copyFromLastWeek() {
    const { lastWeekItems, plannerData } = this.state;

    const newPlannerItems = itemsToPlannerItems(lastWeekItems);

    // If nothing planned then replace the empty planner item
    // with last week's data otherwize append it
    // Also, clear last weeks items, can only use once
    const emptyPlannerData = [{ days: [{}, {}, {}, {}, {}, {}, {}] }];
    const empty =
      plannerData.length === 1 &&
      JSON.stringify(plannerData) === JSON.stringify(emptyPlannerData);

    const updatedPlannerData = empty
      ? newPlannerItems
      : [...newPlannerItems, ...plannerData];
    const sortedPlannerData = sortItemsByTime(updatedPlannerData);
    this.setState({
      plannerData: sortedPlannerData,
      lastWeekItems: undefined,
    });
  }

  validateAndGetFormData() {
    const { weekStartDate } = this.props;
    const { plannerData } = this.state;
    let errorsFound = false;

    const formData = [];

    for (let plannerItemData of plannerData) {
      plannerItemData.errors = [];
      const source = 'planner';
      const plannerType = plannerItemData.daily ? 'daily' : 'general';
      const plannerGroup = uuid();

      if (!plannerItemData.title) {
        errorsFound = true;
        plannerItemData.errors.push('Please enter a title.');
      }

      if (plannerItemData.time > plannerItemData.endTime) {
        errorsFound = true;
        plannerItemData.errors.push('Start time must be before end time.');
      }

      const itemFormData = [];
      if (plannerItemData.days) {
        for (let dayIndex in plannerItemData.days) {
          if (
            plannerItemData.days[dayIndex] &&
            plannerItemData.days[dayIndex].active
          ) {
            const day = plannerItemData.days[dayIndex];
            const assigned_to_id =
              !plannerItemData.daily && plannerItemData.assigned_to_id > 0
                ? plannerItemData.assigned_to_id
                : day.assigned_to_id > 0
                ? day.assigned_to_id
                : undefined;
            itemFormData.push({
              data: { source, plannerType, plannerGroup },
              title: plannerItemData.title,
              description: !plannerItemData.daily
                ? plannerItemData.description
                : day.description,
              assigned_to_id,
              date: moment(weekStartDate)
                .add(dayIndex, 'days')
                .format(DATE_FORMAT),
              time: plannerItemData.time,
              end_time: plannerItemData.endTime,
              send_invite:
                !plannerItemData.daily && plannerItemData.assigned_to_id > 0
                  ? true
                  : day.assigned_to_id > 0
                  ? true
                  : undefined,
            });
          }
        }
      }

      if (itemFormData.length) {
        formData.push(...itemFormData);
      } else {
        errorsFound = true;
        plannerItemData.errors.push('Please select at least one day.');
      }
    }

    if (errorsFound) {
      this.setState({ plannerData });
      return null;
    } else {
      return formData;
    }
  }

  async saveChanges() {
    const { onNewPlannerItems, onRequestClose } = this.props;

    const formData = this.validateAndGetFormData();
    if (!formData) {
      return;
    }
    this.setState({ loading: true });
    await onNewPlannerItems(formData);
    this.setState({ loading: false });
    this.props.history.push('/home/week');
    onRequestClose();
  }

  closeWithoutSaving() {
    const { onRequestClose } = this.props;
    onRequestClose();
  }

  render() {
    const {
      users,
      weekStartDate,
      weekToday,
      weekBack,
      weekForward,
      open,
    } = this.props;
    const { plannerData, loading, lastWeekItems } = this.state;

    return (
      <React.Fragment>
        <Modal
          className="Planner2"
          overlayClassName="Planner2__overlay"
          isOpen={open}
          onRequestClose={() => this.closeWithoutSaving()}
        >
          <div
            style={{
              position: 'sticky',
              top: 0,
              display: 'flex',
              paddingBottom: '20px',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ flex: 1 }}>Weekly Planner</div>
            <img
              src={XIcon}
              alt="X"
              style={{ cursor: 'pointer', width: 24, height: 24 }}
              onClick={() => this.closeWithoutSaving()}
            />
          </div>
          <div className="plannerContainer" style={{ flex: 1 }}>
            <div>
              <div style={{ padding: '20px 0', paddingBottom: 0 }}>
                <div
                  style={{
                    fontSize: '19px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    marginBottom: 20,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <WeekSelect
                    weekStartDate={weekStartDate}
                    weekToday={weekToday}
                    weekBack={weekBack}
                    weekForward={weekForward}
                    hideTodayButton={true}
                  />
                </div>
                <div
                  style={{
                    color: '#666666',
                    fontSize: '15px',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}
                >
                  Let’s get this week planned!
                  {lastWeekItems && lastWeekItems.length > 0 && (
                    <div>
                      <FormButton
                        disabled={loading}
                        type="button"
                        style={{ width: '100%' }}
                        onClick={() => this.copyFromLastWeek()}
                      >
                        Copy from previous week
                      </FormButton>
                    </div>
                  )}
                </div>
              </div>
              {plannerData.map((plannerItemData, i) => {
                return (
                  <Planner2Item
                    key={i}
                    plannerItemData={plannerItemData}
                    onPlannerItemDataChange={newData =>
                      this.onPlannerItemDataChange(i, newData)
                    }
                    onPlannerItemDelete={() => this.onPlannerItemDelete(i)}
                    users={users}
                    weekStartDate={weekStartDate}
                    showDeleteButton={plannerData.length > 1}
                  />
                );
              })}
              <div>
                <FormButton
                  disabled={loading}
                  type="button"
                  style={{ width: '100%' }}
                  onClick={() => this.addPlannerItem()}
                >
                  ＋ Add New Item
                </FormButton>
                <FormButton
                  variant="blue"
                  disabled={loading}
                  type="button"
                  style={{ width: '100%', marginTop: '8px' }}
                  onClick={() => this.saveChanges()}
                >
                  Save &amp; Finish
                </FormButton>
              </div>
            </div>
          </div>
        </Modal>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 100,
              textAlign: 'center',
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Planner2);
